import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

// Components
import {
  Container, Card, CardBody,
} from 'reactstrap';
import Gallery from './components/Gallery';
import FileViewer from './components/FileViewer';
import Toolbar from './components/Toolbar';
import AsyncComponent from '../../shared/components/AsyncComponent';
import UploadModal from './components/UploadModal';
import EditModal from './components/EditModal';
import DeleteConfirmModal from './components/DeleteConfirmModal';
import { containsImagesInUse } from './utils';
import generateTitle from '../../shared/utils/generateTitle';

// Redux
import {
  getFile,
  selectFile,
  toggleUploadModal,
  toggleEditModal,
  toggleViewer,
  deleteFile,
  toggleDeleteConfirmModal,
  changePage,
} from './redux/actions';
import {
  MEDIA_DELETE_FILE, // MEDIA_GET_FILE,
} from './redux/actionCreators';

class Media extends PureComponent {
  static propTypes = {
    // Redux
    mediaActions: PropTypes.instanceOf(Object).isRequired,
    getFile: PropTypes.func.isRequired,
    selectFile: PropTypes.func.isRequired,
    toggleUploadModal: PropTypes.func.isRequired,
    toggleEditModal: PropTypes.func.isRequired,
    toggleViewer: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    toggleDeleteConfirmModal: PropTypes.func.isRequired,
    uploadModalOpen: PropTypes.bool.isRequired,
    editModalOpen: PropTypes.bool.isRequired,
    images: PropTypes.instanceOf(Array),
    selectedFiles: PropTypes.instanceOf(Array).isRequired,
    viewer: PropTypes.bool.isRequired,
    rowsPerPage: PropTypes.number,
    deleteConfirmModalOpen: PropTypes.bool.isRequired,
    inModal: PropTypes.bool,
    imagesCount: PropTypes.number.isRequired,
    changePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,

    // Shared usages
    instance: PropTypes.instanceOf(Object),
  };

  static defaultProps = {
    rowsPerPage: 10,
    inModal: false,

    // Redux
    images: [],

    // Shared Usages
    instance: null,
  };

  componentDidMount() {
    document.title = generateTitle('Media');
    const { getFile: get, viewer } = this.props;

    get({
      page: 0,
      size: viewer ? 10 : 24,
    });
  }

  toggleViewer = () => {
    const { toggleViewer: toggle, getFile: get, viewer } = this.props;

    toggle();
    get({
      page: 0,
      size: !viewer ? 10 : 24,
    });
  };

  toggleUploadModal = () => {
    const { toggleUploadModal: toggle } = this.props;

    toggle();
  };

  handleOnUploadModalOpened = () => {
    // Unselect all files.
    const { selectFile: select } = this.props;

    select({ selected: [] });
  };

  toggleEditModal = () => {
    const { toggleEditModal: toggle } = this.props;

    toggle();
  };

  handleOnEditModalClosed = () => {
    // Unselect all files.
    const { selectFile: select } = this.props;

    select({ selected: [] });
  };

  handleOnSelect = (selected) => {
    const { selectFile: select } = this.props;

    select({ selected });
  };

  handleOnPageChange = (page) => {
    const { getFile: get, changePage: change, viewer } = this.props;

    change({ page });
    get({
      page,
      size: viewer ? 10 : 24,
    });
  };

  handleDeleteFiles = ({ override = false } = {}) => {
    const {
      deleteFile: deleteAction, selectFile: select, selectedFiles, images,
    } = this.props;

    if (!containsImagesInUse(images, selectedFiles) || override) {
      return deleteAction({ ids: selectedFiles })
        .then((action) => {
          if (action.type === MEDIA_DELETE_FILE.SUCCESS) {
            toast(`Successfully deleted file${selectedFiles.length > 1 ? 's' : ''}.`, {
              type: toast.TYPE.SUCCESS,
            });

            select({ selected: [] });
          }
        });
    }
    this.toggleDeleteConfirmModal();

    return null;
  };

  handleDeleteAnyway = () => {
    this.handleDeleteFiles({ override: true })
      .then(() => {
        this.toggleDeleteConfirmModal();
      });
  };

  toggleDeleteConfirmModal = () => {
    const { toggleDeleteConfirmModal: toggle } = this.props;

    toggle();
  };

  handleOnDeleteConfirmModalClosed = () => {
    // Unselect all files.
    const { selectFile: select } = this.props;

    select({ selected: [] });
  };

  getViewerData = () => {
    const { instance, images } = this.props;

    if (instance && instance.imageSet && instance.imageSet.images) {
      return images.filter(f => !instance.imageSet.images.map(p => p.id).includes(f.id));
    }

    return images;
  };

  render() {
    // Props
    const {
      mediaActions: {
        MEDIA_GET_FILE: getFileAction,
        MEDIA_UPLOAD_FILE: uploadFileAction,
        MEDIA_UPDATE_FILE: updateFileAction,
        MEDIA_DELETE_FILE: deleteFileAction,
      },
      uploadModalOpen,
      editModalOpen,
      images,
      selectedFiles,
      viewer,
      rowsPerPage,
      deleteConfirmModalOpen,
      inModal,
      imagesCount,
      page,
    } = this.props;

    const instance = selectedFiles.length && images.filter(f => f.id === parseInt(selectedFiles[0], 10))[0];

    return (
      <Container>
        {!inModal && (
          <>
            <h3 className="page-title">Media</h3>
            <p className="page-subhead">Upload and manage images.</p>
          </>
        )}
        <Card>
          <CardBody>
            <Toolbar
              selected={selectedFiles}
              viewer={viewer}

              // Callbacks
              toggleUploadModal={this.toggleUploadModal}
              toggleEditModal={this.toggleEditModal}
              toggleViewer={this.toggleViewer}
              deleteFiles={this.handleDeleteFiles}
            />
            <AsyncComponent action={getFileAction}>
              {viewer ? (
                <FileViewer
                  data={this.getViewerData()}
                  selected={selectedFiles}
                  rowsPerPage={rowsPerPage}
                  count={imagesCount}
                  page={page}

                  // Callbacks
                  onSelect={this.handleOnSelect}
                  onPageChange={this.handleOnPageChange}
                />
              ) : (
                <Gallery
                  selected={selectedFiles}
                  images={images}
                  page={page}
                  count={imagesCount}

                  // Callbacks
                  onSelect={this.handleOnSelect}
                  onPageChange={this.handleOnPageChange}
                />
              )}
            </AsyncComponent>
          </CardBody>
        </Card>
        <UploadModal
          open={uploadModalOpen}
          action={uploadFileAction}
          inModal={inModal}

          // Callbacks
          toggle={this.toggleUploadModal}
          onOpened={this.handleOnUploadModalOpened}
        />
        <EditModal
          open={editModalOpen}
          action={updateFileAction}

          // Callbacks
          toggle={this.toggleEditModal}
          onClosed={this.handleOnEditModalClosed}

          // Form
          initialValues={instance}
          enableReinitialize
        />
        <DeleteConfirmModal
          open={deleteConfirmModalOpen}
          images={images}
          selected={selectedFiles}
          action={deleteFileAction}
          deleteAnyway={this.handleDeleteAnyway}

          // Callbacks
          toggle={this.toggleDeleteConfirmModal}
          onClosed={this.handleOnDeleteConfirmModalClosed}
        />
      </Container>
    );
  }
}


const mapStateToProps = (state) => {
  const {
    actions: mediaActions,
    uploadModalOpen,
    editModalOpen,
    images,
    imagesCount,
    selectedFiles,
    viewer,
    deleteConfirmModalOpen,
    page,
  } = state.media;

  return {
    mediaActions,
    uploadModalOpen,
    editModalOpen,
    images,
    imagesCount,
    selectedFiles,
    viewer,
    deleteConfirmModalOpen,
    page,
  };
};


const mapDispatchToProps = {
  getFile,
  selectFile,
  toggleUploadModal,
  toggleEditModal,
  toggleViewer,
  deleteFile,
  toggleDeleteConfirmModal,
  changePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Media);
