import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Components
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import Dashboard from '../Dashboard';
import ProtectedRoute from './ProtectedRoute';

import News from '../News';
import NewsEditor from '../News/components/Editor';

import Events from '../Events';
import EventEditor from '../Events/components/Editor';

import Sections from '../Sections';
import SectionEditor from '../Sections/components/Editor';

import Admin from '../Admin';
import AdminSubmissionView from '../Admin/components/SubmissionView';

import MediaHome from '../Media';
import FileViewer from '../Media/components/FileViewer';

import Documents from '../Documents';

import Integrations from '../Integrations';

import Register from '../Account/Register';
import VerifyEmail from '../Account/VerifyEmail';
import ForgotPassword from '../Account/ForgotPassword';
import ResetPassword from '../Account/ResetPassword';
import Login from '../Account/Login';
import Logout from '../Account/Logout';
import Profile from '../Account/Profile';


const news = () => (
  <div>
    <Route exact path="/news" component={News} />
    <Route exact path="/news/editor/:id" component={NewsEditor} />
  </div>
);


const events = () => (
  <div>
    <Route exact path="/events" component={Events} />
    <Route exact path="/events/editor/:id" component={EventEditor} />
  </div>
);


const sections = () => (
  <div>
    <Route exact path="/content" component={Sections} />
    <Route exact path="/content/editor/:slug" component={SectionEditor} />
  </div>
);


const admin = () => (
  <div>
    <Route exact path="/submissions" component={Admin} />
    <Route exact path="/submissions/:id" component={AdminSubmissionView} />
  </div>
);

const documents = () => (
  <div>
    <Route exact path="/documents" component={Documents} />
  </div>
);


const media = () => (
  <div>
    <Route exact path="/media" component={MediaHome} />
    <Route exact path="/media/fileviewer" component={FileViewer} />
  </div>
);


const integrations = () => (
  <div>
    <Route exact path="/integrations" component={Integrations} />
    <Route exact path="/integrations/:integration" component={Integrations} />
  </div>
);


const account = () => (
  <div>
    <Route exact path="/account/profile" component={Profile} />
  </div>
);


const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route exact path="/" component={Dashboard} />
      <Route path="/news" component={news} />
      <Route path="/events" component={events} />
      <ProtectedRoute path="/content" protectionLevel="admin" component={sections} />
      <ProtectedRoute path="/submissions" protectionLevel="admin" component={admin} />
      <ProtectedRoute path="/documents" protectionLevel="admin" component={documents} />
      <Route path="/media" component={media} />
      <ProtectedRoute path="/integrations" protectionLevel="admin" component={integrations} />
      <Route path="/account" component={account} />
    </div>
  </div>
);


const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/verify-email" component={VerifyEmail} />
        <Route exact path="/verify-email/:key" component={VerifyEmail} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password/:uid/:token" component={ResetPassword} />
        <ProtectedRoute path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
