import {
  generateReducerElement as generate, reduxTypes as types, adjust, initializeActions,
} from 'ggtmo-utils';

// Action creators
import {
  MEDIA_TOGGLE_UPLOAD_MODAL,
  MEDIA_TOGGLE_EDIT_MODAL,
  MEDIA_UPLOAD_FILE,
  MEDIA_GET_FILE,
  MEDIA_UPDATE_FILE,
  MEDIA_DELETE_FILE,
  MEDIA_SELECT_FILE,
  MEDIA_TOGGLE_VIEWER,
  MEDIA_TOGGLE_MEDIA_MODAL,
  MEDIA_TOGGLE_DELETE_CONFIRM_MODAL,
  MEDIA_CHANGE_PAGE,
} from './actionCreators';


const initialState = {
  uploadModalOpen: false,
  editModalOpen: false,
  viewer: true,
  page: 0,
  selectedFiles: [],
  images: [],
  mediaModalOpen: false,
  deleteConfirmModalOpen: false,
  actions: initializeActions([
    MEDIA_UPLOAD_FILE,
    MEDIA_GET_FILE,
    MEDIA_UPDATE_FILE,
    MEDIA_DELETE_FILE,
  ]),
};


export default (state = initialState, action) => ({
  ...generate(state, action, MEDIA_TOGGLE_UPLOAD_MODAL, {
    type: types.SYNC,
    adjustment: {
      uploadModalOpen: !state.uploadModalOpen,
    },
  }),

  ...generate(state, action, MEDIA_TOGGLE_EDIT_MODAL, {
    type: types.SYNC,
    adjustment: {
      editModalOpen: !state.editModalOpen,
    },
  }),

  ...generate(state, action, MEDIA_UPLOAD_FILE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, MEDIA_UPLOAD_FILE.SUCCESS) && {
        [action.response.mediaType]: [
          ...action.response.instances,
          ...state[action.response.mediaType],
        ].slice(0, 24),
      },
    },
  }),

  ...generate(state, action, MEDIA_GET_FILE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, MEDIA_GET_FILE.SUCCESS) && {
        [action.response.mediaType]: action.response.instances,
        selectedFiles: initialState.selectedFiles,
        [`${action.response.mediaType}Count`]: action.response.count,
      },
    },
  }),

  ...generate(state, action, MEDIA_UPDATE_FILE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, MEDIA_UPDATE_FILE.SUCCESS) && {
        [action.response.mediaType]: state[action.response.mediaType].map((instance) => {
          if (instance.id === action.response.instance.id) {
            return action.response.instance;
          }
          return instance;
        }),
      },
    },
  }),

  ...generate(state, action, MEDIA_DELETE_FILE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, MEDIA_DELETE_FILE.SUCCESS) && {
        [action.response.mediaType]: state[action.response.mediaType]
          .filter(instance => !action.response.ids.includes(instance.id)),
      },
    },
  }),

  ...generate(state, action, MEDIA_SELECT_FILE, {
    type: types.SYNC,
    adjustment: {
      selectedFiles: action.selected,
    },
  }),

  ...generate(state, action, MEDIA_TOGGLE_VIEWER, {
    type: types.SYNC,
    adjustment: {
      viewer: !state.viewer,
      page: 0,
    },
  }),

  ...generate(state, action, MEDIA_TOGGLE_MEDIA_MODAL, {
    type: types.SYNC,
    adjustment: {
      mediaModalOpen: !state.mediaModalOpen,
    },
  }),

  ...generate(state, action, MEDIA_TOGGLE_DELETE_CONFIRM_MODAL, {
    type: types.SYNC,
    adjustment: {
      deleteConfirmModalOpen: !state.deleteConfirmModalOpen,
    },
  }),

  ...generate(state, action, MEDIA_CHANGE_PAGE, {
    type: types.SYNC,
    adjustment: {
      page: action.page,
    },
  }),
}[action.type] || state);
