import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import { Col } from 'reactstrap';
import Image from './Image';
import UploadImage from './UploadImage';

// Redux
import {
  toggleMediaModal,
  selectFile,
} from '../../../containers/Media/redux/actions';
import MediaModal from '../MediaModal';


export class ImageSetFieldComponent extends PureComponent {
  static propTypes = {
    // Actions
    toggleMediaModal: PropTypes.func.isRequired,
    selectFile: PropTypes.func.isRequired,

    // Media
    mediaModalOpen: PropTypes.bool.isRequired,
    selectedFiles: PropTypes.instanceOf(Array).isRequired,
    images: PropTypes.instanceOf(Array).isRequired,

    // Form
    value: PropTypes.instanceOf(Object),
    onChange: PropTypes.func.isRequired,
    editorForm: PropTypes.instanceOf(Object),
  };

  static defaultProps = {
    // Form
    value: {
      images: [],
    },
    editorForm: {},
  };

  handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { value: { images }, onChange } = this.props;
    const toMove = images.filter(f => f.id === result.draggableId)[0];
    const imagesCopy = [...images];

    imagesCopy.splice(result.source.index, 1);
    imagesCopy.splice(result.destination.index, 0, toMove);

    onChange({ images: imagesCopy });
  };

  handleToggleMediaModal = () => {
    const { toggleMediaModal: toggle } = this.props;
    toggle();
  };

  handleOnAdd = () => {
    const {
      onChange, value, selectedFiles, images, toggleMediaModal: toggle, selectFile: select,
    } = this.props;

    let newImages = [];
    if (!value || !value.images) {
      newImages = images.filter(({ id }) => selectedFiles.includes(id));
    } else if (value.images) {
      newImages = [
        ...value.images,
        ...images.filter(({ id }) => selectedFiles.includes(id)),
      ];
    }

    onChange({ images: newImages });
    toggle();
    select({ selected: [] });
  };

  handleRemoveImage = (event) => {
    const { id } = event.currentTarget.dataset;
    const { value: { images }, onChange } = this.props;

    onChange({ images: images.filter(f => f.id !== parseInt(id, 10)) });
  };

  render() {
    // Props
    const {
      // Media
      mediaModalOpen,

      // Form
      value: { images },
      editorForm,
    } = this.props;

    return (
      <>
        <DragDropContext onDragEnd={this.handleOnDragEnd}>
          <Droppable droppableId="imageSetField" direction="horizontal">
            {droppableProvided => (
              <div
                className="form__image-set-field mb-5 mt-3 mx-0"
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {images && images.map((image, index) => (
                  <Draggable key={image.id} draggableId={image.id} index={index}>
                    {draggableProvided => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                      >
                        <Image
                          {...image}
                          primary={index === 0}

                          // Callbacks
                          onRemove={this.handleRemoveImage}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
                <Col className="pl-0" xs={3}>
                  <UploadImage
                    // Callbacks
                    toggle={this.handleToggleMediaModal}
                  />
                </Col>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <MediaModal
          open={mediaModalOpen}

          // Callbacks
          toggle={this.handleToggleMediaModal}
          onAdd={this.handleOnAdd}

          // Shared usage
          instance={editorForm.values}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    mediaModalOpen,
    selectedFiles,
    images,
  } = state.media;

  const {
    editorForm,
  } = state.form;

  return {
    // Media
    mediaModalOpen,
    selectedFiles,
    images,

    // Form
    editorForm,
  };
};


const mapDispatchToProps = {
  toggleMediaModal,
  selectFile,
};

export const ConnectedImageSetFieldComponent = connect(mapStateToProps, mapDispatchToProps)(ImageSetFieldComponent);

const renderImageSetField = ({ input, ...rest }) => (
  <ConnectedImageSetFieldComponent {...input} {...rest} />
);

renderImageSetField.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
};

export default renderImageSetField;
