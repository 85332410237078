import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { wrapper } from 'ggtmo-utils';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faAt, faKey } from '@fortawesome/pro-light-svg-icons';
import AsyncButton from '../../../../shared/components/AsyncButton';

// Validation
import validate from './validateRegisterForm';


class RegisterForm extends PureComponent {
  static propTypes = {
    action: PropTypes.instanceOf(Object).isRequired,
    handleSubmit: PropTypes.func.isRequired,

    // Form
    invalid: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  renderField = ({
    input, label, type, meta: { touched, error, warning }, icon,
  }) => (
    <div className="form__form-group">
      <span className="form__form-group-label">{label}</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <FontAwesomeIcon icon={icon} />
        </div>
        <input {...input} placeholder={label} type={type} />
      </div>
      <p className="text-danger">
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </p>
    </div>
  );

  render() {
    // State
    const {
      showPassword,
    } = this.state;

    // Props
    const {
      handleSubmit,
      action,

      // Form
      invalid,
    } = this.props;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <Field
          name="name"
          label="Name"
          component={this.renderField}
          type="text"
          placeholder="Name"
          icon={faUser}
        />
        <Field
          name="email"
          label="Email"
          component={this.renderField}
          type="email"
          placeholder="example@mail.com"
          icon={faAt}
        />
        <Field
          name="password1"
          label="Password"
          component={this.renderField}
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          icon={faKey}
        />
        <Field
          name="password2"
          label="Repeat password"
          component={this.renderField}
          type={showPassword ? 'text' : 'password'}
          placeholder="Repeat password"
          icon={faKey}
        />
        <div className="account__btns">
          <AsyncButton className="btn btn-primary account__btn" type="submit" action={action} disabled={invalid}>
            Register
          </AsyncButton>
        </div>
      </form>
    );
  }
}


const withForm = {
  form: 'registerForm',
  validate,
};

export default wrapper({
  component: RegisterForm,
  wrappers: [
    reduxForm(withForm),
  ],
});
