import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { wrapper } from 'ggtmo-utils';

// Components
import { ButtonToolbar, Button } from 'reactstrap';
import Modal from './Modal';
import Media from '../../containers/Media';


class MediaModal extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,

    // Redux
    selectedFiles: PropTypes.instanceOf(Object).isRequired,

    // Callbacks
    toggle: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,

    // Shared usages
    instance: PropTypes.instanceOf(Object),
  };

  static defaultProps = {
    open: false,

    // Shared usages
    instance: {},
  };

  render() {
    // Props
    const {
      open,

      // Redux
      selectedFiles,

      // Callbacks
      toggle,
      onAdd,

      // Shared usages
      instance,
    } = this.props;

    return (
      <Modal
        open={open}
        width="xxl"

        // Callbacks
        toggle={toggle}
      >
        <div>
          <Media
            rowsPerPage={5}
            inModal

            // Shared usages
            instance={instance}
          />
          <div>
            <ButtonToolbar className="float-right">
              <Button onClick={toggle} size="sm">Cancel</Button>
              <Button onClick={onAdd} size="sm" color="primary" disabled={selectedFiles.length === 0}>Add</Button>
            </ButtonToolbar>
          </div>
        </div>
      </Modal>
    );
  }
}


const mapStateToProps = (state) => {
  const {
    selectedFiles,
  } = state.media;

  return {
    selectedFiles,
  };
};

export default wrapper({
  component: MediaModal,
  wrappers: [
    connect(mapStateToProps),
  ],
});
