import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import { Modal } from 'reactstrap';


class ModalComponent extends PureComponent {
  static propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool,
    className: PropTypes.string,
    width: PropTypes.string,

    // Callbacks
    toggle: PropTypes.func.isRequired,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
  };

  static defaultProps = {
    open: false,
    className: '',
    width: 'md',

    // Callbacks
    onOpened: () => {},
    onClosed: () => {},
  };

  getWidth = width => ({
    sm: 200,
    md: 400,
    lg: 600,
    xl: 800,
    xxl: '80%',
  }[width]);

  render() {
    // Props
    const {
      children,
      open,
      className,
      width,

      // Callbacks
      toggle,
      onOpened,
      onClosed,

      // Rest
      ...rest
    } = this.props;

    return (
      <div>
        <Modal
          isOpen={open}
          className={className}
          style={{ width: this.getWidth(width) }}

          // Callbacks
          toggle={toggle}
          onOpened={onOpened}
          onClosed={onClosed}

          // Rest
          {...rest}
        >
          {children}
        </Modal>
      </div>
    );
  }
}

export default ModalComponent;
