import { create } from 'axios';
import { createAxiosInstance } from 'ggtmo-utils';

const axios = createAxiosInstance(create, { baseURL: `${process.env.REACT_APP_BASE_URL}/private/sections/api/v1/` });

// GET
const getSection = params => axios.get('', { params: { list: false, ...params } });
const listSections = params => axios.get('', { params: { list: true, ...params } });
const getPage = params => axios.get('page/', { params: { list: false, ...params } });
const listPages = params => axios.get('page/', { params: { list: true, ...params } });

// POST
const createSection = requestData => axios.post('', requestData);
const createAndPublishSection = requestData => axios.post('', { createAndPublish: true, ...requestData });

// PUT
const updateSection = requestData => axios.put('', requestData);
const updateAndPublishSection = requestData => axios.put('', { updateAndPublish: true, ...requestData });
const publishSection = requestData => axios.put('', { publish: true, ...requestData });
const unpublishSection = requestData => axios.put('', { unpublish: true, ...requestData });
const updatePage = requestData => axios.put('page/', requestData);

// DELETE
const deleteSection = requestData => axios.delete('', { data: requestData });

export default {
  GET: {
    getSection,
    listSections,
    getPage,
    listPages,
  },
  POST: {
    createSection,
    createAndPublishSection,
  },
  PUT: {
    updateSection,
    updateAndPublishSection,
    updatePage,
    publishSection,
    unpublishSection,
  },
  DELETE: {
    deleteSection,
  },
};
