import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import { Container } from 'reactstrap';
import AsyncComponent from '../../shared/components/AsyncComponent';
import TwitterIntegration from './components/TwitterIntegration';

// Redux
import { getIntegration } from './redux/action';

import generateTitle from '../../shared/utils/generateTitle';


class Integrations extends PureComponent {
  static propTypes = {
    // Integrations
    integrationsActions: PropTypes.instanceOf(Object).isRequired,

    // Actions
    getIntegration: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { getIntegration: get } = this.props;
    document.title = generateTitle('Integrations');

    get({
      list: true,
    });
  }

  render() {
    // Props
    const {
      integrationsActions: {
        INTEGRATIONS_GET_INTEGRATION: getIntegrationAction,
      },
    } = this.props;

    return (
      <Container>
        <h3 className="page-title">Integrations</h3>
        <p className="page-subhead">Make use of a range of existing services to improve your website.</p>
        <AsyncComponent action={getIntegrationAction}>
          <TwitterIntegration />
        </AsyncComponent>
      </Container>
    );
  }
}


const mapStateToProps = (state) => {
  const {
    actions: integrationsActions,
  } = state.integrations;

  return {
    // Integrations
    integrationsActions,
  };
};


const mapDispatchToProps = {
  getIntegration,
};

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
