import React from 'react';
import PropTypes from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';


const AsyncRegisterFormErrors = ({ action: { error } }) => {
  if (!error) {
    return null;
  }

  return error.response ? (
    <span>
      <FontAwesomeIcon icon={faExclamationCircle} />
      {' '}{error.response.data.email || error.response.data.password1}
    </span>
  ) : (
    <span>
      <FontAwesomeIcon icon={faExclamationCircle} />
      {' '}{error.message}
    </span>
  );
};

AsyncRegisterFormErrors.propTypes = {
  action: PropTypes.instanceOf(Object).isRequired,
};

export default AsyncRegisterFormErrors;
