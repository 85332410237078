import React from 'react';
import PropTypes from 'prop-types';
import { imageUrl, squashString } from 'ggtmo-utils';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import TableCell from '@material-ui/core/TableCell';
import MatTable from '../../../shared/components/table/MatTable';

// Images
import mediaImage from '../../../shared/img/media.png';


const tableColumns = [
  {
    id: 'preview', label: 'Preview', disablePadding: false, align: 'left',
  },
  {
    id: 'name', label: 'Name', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'inUse', label: 'In use', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'width', label: 'Width', disablePadding: false, numeric: true, align: 'left',
  },
  {
    id: 'height', label: 'Height', disablePadding: false, numeric: true, align: 'left',
  },
];


const TableRow = ({
  url, name, width, height, inUse,
}) => (
  <>
    <TableCell className="material-table__cell">
      <img
        src={imageUrl(url)}
        alt=""
        style={{ width: 100, height: 60, objectFit: 'cover' }}
        className="shadow-sm rounded"
      />
    </TableCell>
    <TableCell className="material-table__cell">{squashString(name)}</TableCell>
    <TableCell className="material-table__cell">
      {inUse ? (
        <FontAwesomeIcon icon={faCheck} className="text-primary" fixedWidth />
      ) : (
        <FontAwesomeIcon icon={faTimes} className="text-danger" fixedWidth />
      )}
    </TableCell>
    <TableCell className="material-table__cell">{width}</TableCell>
    <TableCell className="material-table__cell">{height}</TableCell>
  </>
);

TableRow.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  inUse: PropTypes.bool.isRequired,
};


const FileViewer = ({
  data,
  selected,
  rowsPerPage,
  count,
  page,

  // Callbacks
  onSelect,
  onPageChange,
}) => (
  <div>
    <MatTable
      columns={tableColumns}
      rowComponent={TableRow}
      data={data}
      selected={selected}
      rowsPerPage={rowsPerPage}
      count={count}
      page={page}

      // Callbacks
      onSelect={onSelect}
      onPageChange={onPageChange}
    >
      <div className="text-center">
        <p className="lead">
          No images found.
        </p>
        <img className="w-50" src={mediaImage} alt="" style={{ opacity: 0.5 }} />
      </div>
    </MatTable>
  </div>
);

FileViewer.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.instanceOf(Array).isRequired,
  rowsPerPage: PropTypes.number,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,

  // Callbacks
  onSelect: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

FileViewer.defaultProps = {
  rowsPerPage: 10,
};

export default FileViewer;
