import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';


class AsyncComponent extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    action: PropTypes.instanceOf(Object).isRequired,
    timeoutDuration: PropTypes.number,
    onlyOnInitialLoad: PropTypes.bool,
  };

  static defaultProps = {
    timeoutDuration: 300,
    onlyOnInitialLoad: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      timeout: null,
      showSpinner: false,
      initialLoad: false,
    };
  }

  componentDidMount() {
    const { timeoutDuration } = this.props;

    const timeout = setTimeout(() => this.setState({
      showSpinner: true,
    }), timeoutDuration);
    this.setState({
      timeout,
    });
  }

  componentDidUpdate(prevProps) {
    const { action, children, timeoutDuration } = this.props;

    if ((action.loading !== prevProps.action.loading) && (children !== prevProps.children)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        showSpinner: false,
      });
      const timeout = setTimeout(() => this.setState({
        showSpinner: true,
      }), timeoutDuration);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        timeout,
      });

      if (action.loaded) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          initialLoad: true,
        });
      }
    }
  }

  componentWillUnmount() {
    const { timeout } = this.state;

    clearTimeout(timeout);
  }

  render() {
    // State
    const {
      showSpinner,
      initialLoad,
    } = this.state;

    // Props
    const {
      action: { loading },
      children,
      onlyOnInitialLoad,
    } = this.props;

    return ((!onlyOnInitialLoad && loading) || (!initialLoad && onlyOnInitialLoad) ? (
      <div className="text-center text-primary" style={{ visibility: showSpinner ? 'visible' : 'hidden' }}>
        <FontAwesomeIcon icon={faSpinner} spin size="4x" />
      </div>
    ) : children);
  }
}

export default AsyncComponent;
