import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

// Components
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link } from 'react-router-dom';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import AsyncButton from '../../../../shared/components/AsyncButton';


class LoginForm extends PureComponent {
  static propTypes = {
    action: PropTypes.instanceOf(Object).isRequired,

    // Callbacks
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    // State
    const {
      showPassword,
    } = this.state;

    // Props
    const {
      handleSubmit, action,
    } = this.props;

    const rememberMe = localStorage.getItem('rememberMe');
    const rememberMeBool = rememberMe === 'true' || !!rememberMe;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Email</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="email"
              component="input"
              type="email"
              placeholder="Email"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
            />
            <button
              type="button"
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
            >
              <EyeIcon />
            </button>
          </div>
          <div className="account__forgot-password">
            <Link to="/forgot-password/">Forgot a password?</Link>
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="rememberMe"
              component={renderCheckBoxField}
              defaultChecked={rememberMeBool}
              label="Remember me"
            />
          </div>
        </div>
        <div className="account__btns">
          <AsyncButton className="btn btn-primary account__btn" action={action} size="sm">
            Login
          </AsyncButton>
          <Link className="btn btn-outline-primary account__btn" to="/register">
            Register
          </Link>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'loginForm',
})(LoginForm);
