import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getHeaders } from 'ggtmo-utils';

// Components
import FroalaEditorComponent from 'react-froala-wysiwyg';


class FroalaEditor extends PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    config: PropTypes.instanceOf(Object),

    // Redux
    refreshKey: PropTypes.number.isRequired,
  };

  static defaultProps = {
    className: '',
    config: {},
  };

  handleOnChange = (model) => {
    const { onChange } = this.props;

    onChange(model);
  };

  render() {
    // Env
    const {
      REACT_APP_BASE_URL: baseUrl,
      REACT_APP_FROALA_KEY: froalaKey,
    } = process.env;

    const mediaApiUrl = `${baseUrl}/media/api/v1/`;
    const mediaParams = {
      froala: true,
    };

    // Props
    const {
      value,
      className,
      config,

      // Redux
      refreshKey,
    } = this.props;

    return (
      <div className={className}>
        <FroalaEditorComponent
          key={refreshKey}
          model={value}
          onModelChange={this.handleOnChange}
          config={{
            heightMin: 500,
            toolbarSticky: true,
            toolbarStickyOffset: 60,
            imageManagerLoadMethod: 'GET',
            imageManagerLoadURL: mediaApiUrl,
            imageManagerLoadParams: mediaParams,
            imageManagerDeleteMethod: 'DELETE',
            imageManagerDeleteURL: mediaApiUrl,
            imageManagerDeleteParams: mediaParams,
            imageUploadURL: mediaApiUrl,
            imageUploadParams: mediaParams,
            requestHeaders: { ...getHeaders().headers },
            requestWithCORS: false,
            key: froalaKey,
            attribution: false,
            ...config,
          }}
        />
      </div>
    );
  }
}


const renderFroalaEditor = ({ input, ...rest }) => (
  <FroalaEditor {...input} {...rest} />
);

renderFroalaEditor.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
};


const mapStateToProps = (state) => {
  const {
    key,
  } = state.sections;

  return {
    refreshKey: key,
  };
};

export default connect(mapStateToProps)(renderFroalaEditor);
