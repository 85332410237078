import { generateActionCreator as generate, reduxTypes as types } from 'ggtmo-utils';


export const MEDIA_TOGGLE_UPLOAD_MODAL = generate('MEDIA_TOGGLE_UPLOAD_MODAL', { type: types.SYNC });
export const MEDIA_TOGGLE_EDIT_MODAL = generate('MEDIA_TOGGLE_EDIT_MODAL', { type: types.SYNC });
export const MEDIA_UPLOAD_FILE = generate('MEDIA_UPLOAD_FILE', { type: types.ASYNC });
export const MEDIA_GET_FILE = generate('MEDIA_GET_FILE', { type: types.ASYNC });
export const MEDIA_UPDATE_FILE = generate('MEDIA_UPDATE_FILE', { type: types.ASYNC });
export const MEDIA_DELETE_FILE = generate('MEDIA_DELETE_FILE', { type: types.ASYNC });
export const MEDIA_SELECT_FILE = generate('MEDIA_SELECT_FILE', { type: types.SYNC });
export const MEDIA_TOGGLE_VIEWER = generate('MEDIA_TOGGLE_VIEWER', { type: types.SYNC });
export const MEDIA_TOGGLE_MEDIA_MODAL = generate('MEDIA_TOGGLE_MEDIA_MODAL', { type: types.SYNC });
export const MEDIA_TOGGLE_DELETE_CONFIRM_MODAL = generate('MEDIA_TOGGLE_DELETE_CONFIRM_MODAL', { type: types.SYNC });
export const MEDIA_CHANGE_PAGE = generate('MEDIA_CHANGE_PAGE', { type: types.SYNC });
