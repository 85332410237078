import { generateActionCreator as generate, reduxTypes as types } from 'ggtmo-utils';


// Async
export const NEWS_GET_STORY = generate('NEWS_GET_STORY', { type: types.ASYNC });
export const NEWS_CREATE_STORY = generate('NEWS_CREATE_STORY', { type: types.ASYNC });
export const NEWS_CREATE_AND_PUBLISH_STORY = generate('NEWS_CREATE_AND_PUBLISH_STORY', { type: types.ASYNC });
export const NEWS_UPDATE_STORY = generate('NEWS_UPDATE_STORY', { type: types.ASYNC });
export const NEWS_UPDATE_AND_PUBLISH_STORY = generate('NEWS_UPDATE_AND_PUBLISH_STORY', { type: types.ASYNC });
export const NEWS_DELETE_STORY = generate('NEWS_DELETE_STORY', { type: types.ASYNC });
export const NEWS_PUBLISH_STORY = generate('NEWS_PUBLISH_STORY', { type: types.ASYNC });
export const NEWS_UNPUBLISH_STORY = generate('NEWS_UNPUBLISH_STORY', { type: types.ASYNC });
export const NEWS_PIN_STORY = generate('NEWS_PIN_STORY', { type: types.ASYNC });

// Sync
export const NEWS_SELECT_STORY = generate('NEWS_SELECT_STORY', { type: types.SYNC });
export const NEWS_CLEAR_EDITOR = generate('NEWS_CLEAR_EDITOR', { type: types.SYNC });
export const NEWS_CHANGE_PAGE = generate('NEWS_CHANGE_PAGE', { type: types.SYNC });
