import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';
import { wrapper } from 'ggtmo-utils';
import { toast } from 'react-toastify';

// Components
import { Button, ButtonToolbar } from 'reactstrap';
import Modal from '../../../shared/components/Modal';
import AsyncButton from '../../../shared/components/AsyncButton';
import TextField from '../../../shared/components/form/TextField';

// Redux
import { createDocument } from '../redux/actions';
import { DOCUMENTS_CREATE_DOCUMENT } from '../redux/actionCreators';


class CreateFolderModal extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,

    // Actions
    createDocument: PropTypes.func.isRequired,

    // Documents
    document: PropTypes.instanceOf(Object).isRequired,

    // Form
    createFolderForm: PropTypes.instanceOf(Object).isRequired,
    reset: PropTypes.func.isRequired,

    // Callbacks
    toggle: PropTypes.func.isRequired,
  };

  static defaultProps = {
    open: false,
  };

  handleOnSubmit = (event) => {
    event.preventDefault();
    const {
      createDocument: create, createFolderForm, toggle, document,
    } = this.props;

    create({
      ...createFolderForm.values,
      type: 'folder',
      parent: document.id,
    })
      .then((action) => {
        if (action.type === DOCUMENTS_CREATE_DOCUMENT.SUCCESS) {
          toast('Successfully created folder.', {
            type: toast.TYPE.SUCCESS,
          });
          toggle();
        }
      });
  };

  handleOnClosed = () => {
    const { reset } = this.props;

    reset();
  };

  render() {
    // Props
    const {
      open,

      // Callbacks
      toggle,
    } = this.props;

    return (
      <Modal
        open={open}
        onClosed={this.handleOnClosed}

        // Callbacks
        toggle={toggle}
      >
        <h3 className="page-title">
          Create folder
        </h3>
        <Form className="material-form mt-4 text-left" onSubmit={this.handleOnSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">Name</span>
            <Field
              className="material-form__field"
              name="name"
              component={TextField}
            />
          </div>
          <ButtonToolbar className="justify-content-end">
            <Button onClick={toggle} size="sm">Cancel</Button>
            <AsyncButton size="sm" color="primary" type="submit">Create</AsyncButton>
          </ButtonToolbar>
        </Form>
      </Modal>
    );
  }
}


const mapStateToProps = (state) => {
  const {
    document,
  } = state.documents;

  const {
    createFolderForm,
  } = state.form;

  return {
    // Documents
    document,

    // Form
    createFolderForm,
  };
};


const mapDispatchToProps = {
  createDocument,
};


const withForm = {
  form: 'createFolderForm',
};


export default wrapper({
  component: CreateFolderModal,
  wrappers: [
    reduxForm(withForm),
    connect(mapStateToProps, mapDispatchToProps),
  ],
});
