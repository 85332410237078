import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { squashString } from 'ggtmo-utils';
import styled from 'styled-components';

// Components
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import { Col, Label, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt, faPlus, faQuestionCircle, faTimes,
} from '@fortawesome/pro-light-svg-icons';
import DocumentsModal from '../DocumentsModal';
import { CheckBoxField as CheckBox } from './CheckBox';

// Redux
import { toggleDocumentsModal, selectDocument } from '../../../containers/Documents/redux/actions';


const RemoveDocumentButton = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
`;


class DocumentSetField extends Component {
  static propTypes = {
    // Form
    value: PropTypes.instanceOf(Object),
    onChange: PropTypes.func.isRequired,
    editorForm: PropTypes.instanceOf(Object).isRequired,

    // Actions
    toggleDocumentsModal: PropTypes.func.isRequired,
    selectDocument: PropTypes.func.isRequired,

    // Documents
    documents: PropTypes.instanceOf(Array).isRequired,
    selectedDocuments: PropTypes.instanceOf(Array).isRequired,
    documentsModalOpen: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    // Form
    value: {
      images: [],
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { value: { documents }, onChange } = this.props;
    const toMove = documents.filter(document => document.id === result.draggableId)[0];
    const documentsCopy = [...documents];

    documentsCopy.splice(result.source.index, 1);
    documentsCopy.splice(result.destination.index, 0, toMove);
    onChange({ documents: documentsCopy });
  };

  handleToggleDocumentsModal = () => {
    const { toggleDocumentsModal: toggle } = this.props;
    toggle();
  };

  handleOnAddDocument = () => {
    const {
      selectedDocuments, documents, onChange, toggleDocumentsModal: toggle, selectDocument: select, editorForm,
    } = this.props;

    let newDocuments = [];
    if (!editorForm.values.child.documentSet) {
      newDocuments = documents.filter(document => selectedDocuments.includes(document.id));
    } else if (editorForm.values.child.documentSet.documents) {
      newDocuments = [
        ...editorForm.values.child.documentSet.documents,
        ...documents.filter(document => selectedDocuments.includes(document.id)),
      ];
    }

    onChange({ documents: newDocuments });
    toggle();
    select({ selected: [] });
  };

  handleOnRemoveDocument = (event) => {
    const { id } = event.currentTarget.dataset;
    const { value: { documents }, onChange } = this.props;
    onChange({ documents: documents.filter(document => document.id !== parseInt(id, 10)) });
  };

  handleOnShowChange = (event) => {
    this.setState({
      show: event.target.checked,
    });
  };

  renderDocument = (document, index) => (
    <Draggable key={document.id} draggableId={document.id} index={index}>
      {draggableProvided => (
        <div
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
        >
          <div className="form__image-set-field__upload-image text-center rounded position-relative">
            <div className="d-table-cell" style={{ verticalAlign: 'middle' }}>
              <FontAwesomeIcon icon={faFileAlt} fixedWidth size="4x" />
              <p>{squashString(document.name, { filename: true, length: 15 })}</p>
            </div>
            <RemoveDocumentButton>
              <FontAwesomeIcon
                icon={faTimes}
                className="hover-text-red mt-2 mr-2"
                onClick={this.handleOnRemoveDocument}
                data-id={document.id}
                style={{ fontSize: '1.25rem' }}
              />
            </RemoveDocumentButton>
          </div>
        </div>
      )}
    </Draggable>
  );

  renderAddDocumentButton = () => (
    <div className="bg-white rounded form__image-set-field__upload-image text-center text-black-50">
      <div role="presentation" onClick={this.handleToggleDocumentsModal}>
        <FontAwesomeIcon icon={faPlus} size="4x" />
        <p>Add document</p>
      </div>
    </div>
  );

  render() {
    // State
    const {
      show: showInternal,
    } = this.state;

    // Props
    const {
      value: { documents },

      // Documents
      documentsModalOpen,

      // Form
      editorForm,
    } = this.props;

    const show = showInternal || (documents && documents.length !== 0);

    return (
      <>
        <div
          className={classNames('d-flex p-2', { rounded: !show })}
          style={{ border: show ? '1px solid transparent' : '1px solid #CCCCCC' }}
        >
          <CheckBox
            setOnDidMount={false}
            id="showTimeSelect"
            value={show}
            onChange={this.handleOnShowChange}
          />
          <Label for="showTimeSelect" className="mb-0">
            Documents{' '}
            {!show && (
              <>
                <FontAwesomeIcon icon={faQuestionCircle} fixedWidth id="documents-add-help" />
                <UncontrolledTooltip target="documents-add-help">
                  Tick this option to add documents to this section.
                </UncontrolledTooltip>
              </>
            )}
          </Label>
        </div>
        {show && (
          <DragDropContext onDragEnd={this.handleOnDragEnd}>
            <Droppable droppableId="imageSetField" direction="horizontal">
              {droppableProvided => (
                <div
                  className="form__image-set-field mb-5 mt-3 mx-0"
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  {documents && documents.map((document, index) => this.renderDocument(document, index))}
                  {droppableProvided.placeholder}
                  <Col className="pl-0" xs={3}>
                    {this.renderAddDocumentButton()}
                  </Col>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <DocumentsModal
          open={documentsModalOpen}

          // Callbacks
          toggle={this.handleToggleDocumentsModal}
          onAdd={this.handleOnAddDocument}

          // Shared usage
          instance={editorForm.values}
        />
      </>
    );
  }
}


const renderDocumentSetField = ({ input, ...rest }) => (
  <DocumentSetField {...input} {...rest} />
);

renderDocumentSetField.propTypes = {
  // Form
  input: PropTypes.instanceOf(Object).isRequired,
};


const mapStateToProps = (state) => {
  const {
    documents,
    selectedDocuments,
    documentsModalOpen,
  } = state.documents;

  const {
    editorForm,
  } = state.form;

  return {
    // Documents
    documents,
    selectedDocuments,
    documentsModalOpen,

    // Form
    editorForm,
  };
};


const mapDispatchToProps = {
  toggleDocumentsModal,
  selectDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(renderDocumentSetField);
