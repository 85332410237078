import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import { ButtonToolbar, Button } from 'reactstrap';
import Modal from './Modal';
import Documents from '../../containers/Documents';


class DocumentsModal extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,

    // Documents
    selectedDocuments: PropTypes.instanceOf(Object).isRequired,

    // Callbacks
    toggle: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,

    // Shared usages
    instance: PropTypes.instanceOf(Object),
  };

  static defaultProps = {
    open: false,

    // Shared usages
    instance: {},
  };

  render() {
    // Props
    const {
      open,

      // Documents
      selectedDocuments,

      // Callbacks
      toggle,
      onAdd,

      // Shared usages
      instance,
    } = this.props;

    return (
      <Modal
        open={open}
        width="xxl"

        // Callbacks
        toggle={toggle}
      >
        <div>
          <Documents
            rowsPerPage={5}
            inModal

            // Shared usages
            instance={instance}
          />
          <div>
            <ButtonToolbar className="float-right">
              <Button onClick={toggle} size="sm">Cancel</Button>
              <Button onClick={onAdd} size="sm" color="primary" disabled={selectedDocuments.length === 0}>Add</Button>
            </ButtonToolbar>
          </div>
        </div>
      </Modal>
    );
  }
}


const mapStateToProps = (state) => {
  const {
    selectedDocuments,
  } = state.documents;

  return {
    selectedDocuments,
  };
};

export default connect(mapStateToProps)(DocumentsModal);
