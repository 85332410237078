import { generateReducerElement as generate, reduxTypes as types, initializeActions } from 'ggtmo-utils';

// Action Creators
import {
  // Async
  SECTIONS_GET_SECTION,
  SECTIONS_LIST_SECTIONS,
  SECTIONS_CREATE_SECTION,
  SECTIONS_CREATE_AND_PUBLISH_SECTION,
  SECTIONS_UPDATE_SECTION,
  SECTIONS_UPDATE_AND_PUBLISH_SECTION,
  SECTIONS_DELETE_SECTION,
  SECTIONS_PUBLISH_SECTION,
  SECTIONS_UNPUBLISH_SECTION,
  SECTIONS_GET_PAGE,
  SECTIONS_LIST_PAGES,
  SECTIONS_UPDATE_PAGE,

  // Sync
  SECTIONS_SELECT_SECTION,
  SECTIONS_CLEAR_EDITOR,
  SECTIONS_UPDATE_KEY,
} from './actionCreators';

const initialState = {
  sections: [],
  section: {},
  page: {},
  pages: [],
  key: 0,
  actions: initializeActions([
    SECTIONS_GET_SECTION,
    SECTIONS_LIST_SECTIONS,
    SECTIONS_CREATE_SECTION,
    SECTIONS_CREATE_AND_PUBLISH_SECTION,
    SECTIONS_UPDATE_SECTION,
    SECTIONS_UPDATE_AND_PUBLISH_SECTION,
    SECTIONS_DELETE_SECTION,
    SECTIONS_PUBLISH_SECTION,
    SECTIONS_UNPUBLISH_SECTION,
    SECTIONS_GET_PAGE,
    SECTIONS_LIST_PAGES,
    SECTIONS_UPDATE_PAGE,
  ]),
};

// TODO: Add some error that is thrown if types.SYNC and adjustments are set.
export default (state = initialState, action) => ({
  // Async
  ...generate(state, action, SECTIONS_GET_SECTION, { type: types.ASYNC }),
  ...generate(state, action, SECTIONS_LIST_SECTIONS, { type: types.ASYNC }),
  ...generate(state, action, SECTIONS_CREATE_SECTION, { type: types.ASYNC }),
  ...generate(state, action, SECTIONS_CREATE_AND_PUBLISH_SECTION, { type: types.ASYNC }),
  ...generate(state, action, SECTIONS_UPDATE_SECTION, { type: types.ASYNC }),
  ...generate(state, action, SECTIONS_UPDATE_AND_PUBLISH_SECTION, { type: types.ASYNC }),
  ...generate(state, action, SECTIONS_DELETE_SECTION, { type: types.ASYNC }),
  ...generate(state, action, SECTIONS_PUBLISH_SECTION, { type: types.ASYNC }),
  ...generate(state, action, SECTIONS_UNPUBLISH_SECTION, { type: types.ASYNC }),
  ...generate(state, action, SECTIONS_GET_PAGE, { type: types.ASYNC }),
  ...generate(state, action, SECTIONS_LIST_PAGES, { type: types.ASYNC }),
  ...generate(state, action, SECTIONS_UPDATE_PAGE, { type: types.SYNC }),

  // Sync
  ...generate(state, action, SECTIONS_SELECT_SECTION, {
    type: types.SYNC,
    adjustment: {
      selectedSection: action.id,
    },
  }),
  ...generate(state, action, SECTIONS_CLEAR_EDITOR, {
    type: types.SYNC,
    adjustment: {
      section: initialState.section,
      actions: {
        ...state.actions,
        ...initializeActions([
          SECTIONS_GET_SECTION,
        ]),
      },
    },
  }),
  ...generate(state, action, SECTIONS_UPDATE_KEY, {
    type: types.SYNC,
    adjustment: {
      key: action.key,
    },
  }),
}[action.type] || state);
