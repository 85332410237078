/* eslint-disable max-len */
import { generateActionCreator as generate, reduxTypes as types } from 'ggtmo-utils';

// Async - sections
export const SECTIONS_GET_SECTION = generate('SECTIONS_GET_SECTION', { type: types.ASYNC });
export const SECTIONS_LIST_SECTIONS = generate('SECTIONS_LIST_SECTIONS', { type: types.ASYNC });
export const SECTIONS_CREATE_SECTION = generate('SECTIONS_CREATE_SECTION', { type: types.ASYNC });
export const SECTIONS_CREATE_AND_PUBLISH_SECTION = generate('SECTIONS_CREATE_AND_PUBLISH_SECTION', { type: types.ASYNC });
export const SECTIONS_UPDATE_SECTION = generate('SECTIONS_UPDATE_SECTION', { type: types.ASYNC });
export const SECTIONS_UPDATE_AND_PUBLISH_SECTION = generate('SECTIONS_UPDATE_AND_PUBLISH_SECTION', { type: types.ASYNC });
export const SECTIONS_DELETE_SECTION = generate('SECTIONS_DELETE_SECTION', { type: types.ASYNC });
export const SECTIONS_PUBLISH_SECTION = generate('SECTIONS_PUBLISH_SECTION', { type: types.ASYNC });
export const SECTIONS_UNPUBLISH_SECTION = generate('SECTIONS_UNPUBLISH_SECTION', { type: types.ASYNC });

// Async - pages
export const SECTIONS_GET_PAGE = generate('SECTIONS_GET_PAGE', { type: types.ASYNC });
export const SECTIONS_LIST_PAGES = generate('SECTIONS_LIST_PAGES', { type: types.ASYNC });
export const SECTIONS_UPDATE_PAGE = generate('SECTIONS_UPDATE_PAGE', { type: types.ASYNC });

// Sync
export const SECTIONS_SELECT_SECTION = generate('SECTIONS_SELECT_SECTION', { type: types.SYNC });
export const SECTIONS_CLEAR_EDITOR = generate('SECTIONS_CLEAR_EDITOR', { type: types.SYNC });
export const SECTIONS_UPDATE_KEY = generate('SECTIONS_UPDATE_KEY', { type: types.SYNC });
