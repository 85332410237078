import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { wrapper } from 'ggtmo-utils';

// Components
import LoginForm from './components/LoginForm';
import AsyncLoginFormErrors from './components/AsyncLoginFormErrors';

// Redux
import {
  login,
} from '../redux/actions';
import {
  ACCOUNT_LOGIN,
} from '../redux/actionCreators';

// Images
import logo from '../../../shared/img/logo/logo_light.png';


class Login extends PureComponent {
  static propTypes = {
    // Redux
    dispatch: PropTypes.func.isRequired,
    action: PropTypes.instanceOf(Object).isRequired,

    // Form
    loginForm: PropTypes.instanceOf(Object).isRequired,

    // Router
    history: ReactRouterPropTypes.history.isRequired,
  };

  login = (event) => {
    const { dispatch, loginForm, history } = this.props;

    event.preventDefault();
    dispatch(login(loginForm.values))
      .then((action) => {
        if (action.type === ACCOUNT_LOGIN.SUCCESS) {
          sessionStorage.setItem('token', action.response.token);
          localStorage.setItem('token', action.response.token);
          localStorage.setItem('rememberMe', loginForm.values.rememberMe);
          localStorage.setItem('user', JSON.stringify(action.response.user));
          if (!loginForm.values.rememberMe) {
            window.onunload = () => {
              localStorage.removeItem('token');
            };
          }

          history.push('/');
        }
      });
  };

  render() {
    // Props
    const {
      action,
    } = this.props;

    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card shadow">
            <img src={logo} alt="" className="mb-4" />
            <div className="text-danger my-2">
              <AsyncLoginFormErrors action={action} />
            </div>
            <LoginForm
              handleSubmit={this.login}
              action={action}
            />
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const {
    actions: { ACCOUNT_LOGIN: action },
  } = state.account;
  const { loginForm } = state.form;

  return {
    loginForm,
    action,
  };
};


export default wrapper({
  component: Login,
  wrappers: [
    connect(mapStateToProps),
  ],
});
