import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import { composeWithDevTools } from 'redux-devtools-extension';
import { sidebarReducer, themeReducer } from '../../redux/reducers/index';
import newsReducer from '../News/redux/reducer';
import eventsReducer from '../Events/redux/reducer';
import sectionsReducer from '../Sections/redux/reducer';
import adminReducer from '../Admin/redux/reducer';
import documentsReducer from '../Documents/redux/reducer';
import mediaReducer from '../Media/redux/reducer';
import integrationsReducer from '../Integrations/redux/reducer';
import accountReducer from '../Account/redux/reducer';
import editorReducer from '../../redux/reducers/editorReducer';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  news: newsReducer,
  events: eventsReducer,
  sections: sectionsReducer,
  admin: adminReducer,
  documents: documentsReducer,
  account: accountReducer,
  integrations: integrationsReducer,
  media: mediaReducer,
  editor: editorReducer,
});


const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

export default store;
