import styled from 'styled-components';


export const LoadingBarWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
`;


export const LoadingBar = styled.div`
  position: relative;
  width: ${({ progress }) => progress}%;
  height: 24px;
  background-color: #106c12;
`;


export const LoadingBarText = styled.span`
  position: absolute;
  left: 6px;
  color: white;
  line-height: 24px;
`;


export const LoadingBarPercentage = styled.span`
  position: absolute;
  right: 6px;
  line-height: 24px;
  color: white;
`;
