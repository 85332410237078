import React from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router';
import { reduxForm } from 'redux-form';
import { wrapper } from 'ggtmo-utils';
import { toast } from 'react-toastify';

// Components
import {
  Container, Row, Col, Card, CardBody, UncontrolledTooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRandom, faSpinner } from '@fortawesome/pro-light-svg-icons';
import EditorForm from './EditorForm';
import AsyncComponent from '../../../shared/components/AsyncComponent';
import EditorControls from '../../../shared/components/EditorControls';
import MediaModal from '../../../shared/components/MediaModal';
import DeleteConfirmModal from '../../../shared/components/DeleteConfirmModal';
import EditorError from '../../../shared/components/EditorError';

// Utils
import getUserType from '../../../shared/utils/getUserType';

// Redux - editor
import {
  toggleDeleteConfirmModal,
} from '../../../redux/actions/editorActions';

// Redux - events
import {
  // Async
  EVENTS_CREATE_EVENT,
  EVENTS_CREATE_AND_PUBLISH_EVENT,
  EVENTS_UPDATE_EVENT,
  EVENTS_UPDATE_AND_PUBLISH_EVENT,
  EVENTS_DELETE_EVENT,
  EVENTS_PUBLISH_EVENT,
  EVENTS_UNPUBLISH_EVENT,
  EVENTS_CONVERT_TO_STORY,
} from '../redux/actionCreators';
import {
  // Async
  getEvent,
  createEvent,
  createAndPublishEvent,
  updateEvent,
  updateAndPublishEvent,
  deleteEvent,
  publishEvent,
  unpublishEvent,
  convertToStory,

  // Sync
  clearEditor,
} from '../redux/actions';

// Redux - media
import {
  // Sync
  selectFile,
  toggleMediaModal,
} from '../../Media/redux/actions';

// Redux - admin
import {
  // Async
  ADMIN_CREATE_SUBMISSION,
} from '../../Admin/redux/actionCreators';
import {
  // Async
  createSubmission,
} from '../../Admin/redux/actions';

// Classes
import BaseEditor from '../../../shared/BaseEditor';


class EventEditor extends BaseEditor {
  static propTypes = {
    // Redux
    eventsActions: PropTypes.instanceOf(Object).isRequired,
    event: PropTypes.instanceOf(Object).isRequired,
    mediaModalOpen: PropTypes.bool.isRequired,
    selectedFiles: PropTypes.instanceOf(Array).isRequired,
    images: PropTypes.instanceOf(Array).isRequired,
    deleteConfirmModalOpen: PropTypes.bool.isRequired,

    // Router
    history: RouterPropTypes.history.isRequired,

    // Form
    editorForm: PropTypes.instanceOf(Object),
    change: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    // Form
    editorForm: {},
  };

  actionCreators = {
    create: EVENTS_CREATE_EVENT,
    createAndPublish: EVENTS_CREATE_AND_PUBLISH_EVENT,
    update: EVENTS_UPDATE_EVENT,
    updateAndPublish: EVENTS_UPDATE_AND_PUBLISH_EVENT,
    delete: EVENTS_DELETE_EVENT,
    publish: EVENTS_PUBLISH_EVENT,
    unpublish: EVENTS_UNPUBLISH_EVENT,
    convertToStory: EVENTS_CONVERT_TO_STORY,
    submit: ADMIN_CREATE_SUBMISSION,
  };

  appName = 'events';

  modelName = 'event';

  toastMessages = {
    create: {
      SUCCESS: 'Created event.',
      ERROR: 'Error creating event.',
    },
    createAndPublish: {
      SUCCESS: 'Created and published event. Your changes are visible to the public.',
      ERROR: 'Error creating event.',
    },
    update: {
      SUCCESS: 'Saved event.',
      ERROR: 'Error saving event.',
    },
    updateAndPublish: {
      SUCCESS: 'Saved and published event. Your changes are visible to the public.',
      ERROR: 'Error saving event.',
    },
    delete: {
      SUCCESS: 'Deleted event,',
      ERROR: 'Error deleting event.',
    },
    publish: {
      SUCCESS: 'Published event. Your changes are visible to the public.',
      ERROR: 'Error publishing event.',
    },
    unpublish: {
      SUCCESS: 'Unpublished event. It is no longer visible to the public.',
      ERROR: 'Error unpublishing event.',
    },
    submit: {
      SUCCESS: 'Submitted for review. You will be notified if it is approved for publish.',
      ERROR: 'Error submitting for review.',
    },
  };

  handleOnConvertToStory = () => {
    const { convertToStory: convert, event, history } = this.props;

    convert({ id: event.id })
      .then((action) => {
        if (action.type === EVENTS_CONVERT_TO_STORY.SUCCESS) {
          history.push(`/news/editor/${action.response.story.id}`);
          toast('Successfully converted event to a story', {
            type: toast.TYPE.SUCCESS,
          });
        }
      });
  };

  renderCustomPills = () => {
    // Props
    const {
      eventsActions: {
        EVENTS_CONVERT_TO_STORY: convertToStoryAction,
      },
      event,
    } = this.props;

    return event.isLive ? (
      <>
        <span
          role="presentation"
          id="convert-to-story"
          className="editor__controls__pin-btn shadow-sm hover-pointer"
          onClick={this.handleOnConvertToStory}
        >
          <FontAwesomeIcon
            icon={convertToStoryAction.loading ? faSpinner : faRandom}
            fixedWidth
            spin={convertToStoryAction.loading}
          />
          {' '}Convert to story
        </span>
        <UncontrolledTooltip target="convert-to-story">
          Unpublish this event and create a new story with the title and images copied over.
        </UncontrolledTooltip>
      </>
    ) : null;
  };

  render() {
    // Props
    const {
      eventsActions: {
        EVENTS_GET_EVENT: getEventAction,
        EVENTS_CREATE_EVENT: createEventAction,
        EVENTS_CREATE_AND_PUBLISH_EVENT: createAndPublishEventAction,
        EVENTS_UPDATE_EVENT: updateEventAction,
        EVENTS_UPDATE_AND_PUBLISH_EVENT: updateAndPublishEventAction,
        EVENTS_DELETE_EVENT: deleteEventAction,
        EVENTS_PUBLISH_EVENT: publishEventAction,
        EVENTS_UNPUBLISH_EVENT: unpublishEventAction,
      },
      event,
      editorForm,
      mediaModalOpen,
      pristine,
      dirty,
      match: { params },
      deleteConfirmModalOpen,
    } = this.props;

    if (getEventAction.error) {
      return (
        <EditorError
          instanceType="event"
          action={getEventAction}
        />
      );
    }

    return (
      <>
        <Prompt message="You have made unsaved changes. Are you sure you want to leave the page?" when={dirty} />
        <Container>
          <Row>
            <Col xs={8}>
              <Card>
                <CardBody>
                  <AsyncComponent action={getEventAction}>
                    <EditorForm
                      initialValues={event}
                      enableReinitialize
                    />
                  </AsyncComponent>
                </CardBody>
              </Card>
            </Col>
            <Col xs={3} style={{ height: '100%' }}>
              <Card>
                <CardBody className="shadow">
                  <EditorControls
                    userType={getUserType()}
                    instanceType="event"
                    isNew={params.id === 'new'}
                    instance={event}
                    pristine={pristine}

                    // Components
                    customPills={this.renderCustomPills}

                    // Actions
                    createAction={createEventAction}
                    createAndPubluishAction={createAndPublishEventAction}
                    updateAction={updateEventAction}
                    updateAndPublishAction={updateAndPublishEventAction}
                    deleteAction={deleteEventAction}
                    publishAction={publishEventAction}
                    unpublishAction={unpublishEventAction}

                    // Callbacks
                    onCreate={this.handleOnCreate}
                    onCreateAndPublish={this.handleOnCreateAndPublish}
                    onUpdate={this.handleOnUpdate}
                    onUpdateAndPublish={this.handleOnUpdateAndPublish}
                    onDelete={this.handleOnToggleDeleteConfirmModal}
                    onPublish={this.handleOnPublish}
                    onUnpublish={this.handleOnUnpublish}
                    onSubmit={this.handleOnSubmit}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <MediaModal
          open={mediaModalOpen}

          // Callbacks
          toggle={this.handleToggleMediaModal}

          // Shared usage
          instance={editorForm.values}
        />
        <DeleteConfirmModal
          open={deleteConfirmModalOpen}
          action={deleteEventAction}
          deleteAnyway={this.handleOnDelete}
          instanceType="event"

          // Callbacks
          toggle={this.handleOnToggleDeleteConfirmModal}
        />
      </>
    );
  }
}


const mapStateToProps = (state) => {
  const {
    deleteConfirmModalOpen,
  } = state.editor;

  const {
    actions: eventsActions,
    event,
  } = state.events;

  const {
    mediaModalOpen,
    selectedFiles,
    images,
  } = state.media;

  const {
    editorForm,
  } = state.form;

  return {
    eventsActions,
    event,
    instance: event,
    mediaModalOpen,
    selectedFiles,
    images,
    editorForm,
    deleteConfirmModalOpen,
  };
};


const mapDispatchToProps = {
  // Async
  get: getEvent,
  create: createEvent,
  createAndPublish: createAndPublishEvent,
  update: updateEvent,
  updateAndPublish: updateAndPublishEvent,
  delete: deleteEvent,
  publish: publishEvent,
  unpublish: unpublishEvent,
  convertToStory,
  submit: createSubmission,

  // Sync
  selectFile,
  toggleMediaModal,
  clearEditor,
  toggleDeleteConfirmModal,
};


const withForm = {
  form: 'editorForm',
};

export default wrapper({
  component: EventEditor,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    reduxForm(withForm),
  ],
});
