import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';
import { toast } from 'react-toastify';
import { wrapper } from 'ggtmo-utils';
import { Spring } from 'react-spring/renderprops-universal';

// Components
import { Button, ButtonToolbar } from 'reactstrap';
import Modal from '../../../shared/components/Modal';
import AsyncButton from '../../../shared/components/AsyncButton';
import {
  LoadingBarWrapper,
  LoadingBar,
  LoadingBarText,
  LoadingBarPercentage,
} from '../../../shared/ProgressBar';

// Form
import renderDropZoneMultipleField from '../../../shared/components/form/DropZoneMultiple';
import Select from '../../../shared/components/form/Select';

// Redux
import {
  toggleUploadModal,
  uploadFile,
  selectFile,
} from '../redux/actions';
import {
  MEDIA_UPLOAD_FILE,
} from '../redux/actionCreators';


class UploadModal extends Component {
  static propTypes = {
    open: PropTypes.bool,
    action: PropTypes.instanceOf(Object).isRequired,
    inModal: PropTypes.bool.isRequired,

    // Form
    uploadForm: PropTypes.instanceOf(Object),
    reset: PropTypes.func.isRequired,

    // Actions
    uploadFile: PropTypes.func.isRequired,
    toggleUploadModal: PropTypes.func.isRequired,
    selectFile: PropTypes.func.isRequired,

    // Callbacks
    toggle: PropTypes.func.isRequired,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
  };

  static defaultProps = {
    open: false,

    // Form
    uploadForm: {
      values: {
        files: [],
      },
    },

    // Callbacks
    onOpened: () => {},
    onClosed: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      uploadProgress: 0,
    };
  }

  isDisabled = (values) => {
    if (!values || !values.files) return true;

    return values.files.length === 0 || !values.category;
  };

  handleOnSubmit = (event) => {
    event.preventDefault();

    const {
      uploadForm, uploadFile: upload, toggleUploadModal: toggle, inModal, selectFile: select,
    } = this.props;
    const { files } = uploadForm.values;

    const formData = new FormData();
    for (let i = 0; i < files.length; i += 1) {
      formData.append(`file${i}`, files[i]);
    }
    formData.append('category', uploadForm.values.category.value);

    upload(formData, {
      additionalConfig: {
        onUploadProgress: progressEvent => this.setState({
          uploadProgress: Math.round(progressEvent.loaded * 100 / progressEvent.total),
        }),
      },
    })
      .then((action) => {
        if (action.type === MEDIA_UPLOAD_FILE.SUCCESS) {
          toggle();

          if (inModal) {
            select({ selected: action.response.instances.map(instance => instance.id) });
          }

          const { count } = action.response;
          toast(`Successfully uploaded ${count} file${count === 1 ? '' : 's'}.`, {
            type: toast.TYPE.SUCCESS,
          });
        }
      });
  };

  handleOnClosed = () => {
    const { reset } = this.props;

    reset();
  };

  render() {
    // State
    const {
      uploadProgress,
    } = this.state;

    // Props
    const {
      open,
      action,

      // Form
      uploadForm,

      // Callbacks
      toggle,
      onOpened,
      onClosed,
    } = this.props;

    return (
      <Modal
        open={open}
        width="xl"

        // Callbacks
        toggle={toggle}
        onOpened={onOpened}
        onClosed={onClosed || this.handleOnClosed}
      >
        <div className="modal__body text-left">
          <Form onSubmit={this.handleOnSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Upload your files{' '}
                <span className="text-black-50">
                  (try to upload only one type of image at once, i.e. upload photos and icons separately)
                </span>
              </span>
              <Field
                name="files"
                component={renderDropZoneMultipleField}
              />
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">What category should these images go in?</span>
              <Field
                name="category"
                component={Select}
                options={[
                  { value: '1', label: 'Photo' },
                  { value: '2', label: 'Icon' },
                  { value: '3', label: 'Illustration' },
                ]}
              />
            </div>
            {action.loading && (
              <div className="w-100">
                <Spring from={{ progress: 0 }} to={{ progress: uploadProgress }}>
                  {({ progress }) => (
                    <LoadingBarWrapper className="rounded">
                      <LoadingBar progress={progress} className="rounded">
                        <LoadingBarText>Uploading...</LoadingBarText>
                        {uploadProgress > 20 && (
                          <LoadingBarPercentage>
                            {Math.round(progress)}%
                          </LoadingBarPercentage>
                        )}
                      </LoadingBar>
                    </LoadingBarWrapper>
                  )}
                </Spring>
              </div>
            )}
            <ButtonToolbar className="modal__footer">
              <Button onClick={toggle}>Cancel</Button>{' '}
              <AsyncButton
                color="primary"
                type="submit"
                disabled={this.isDisabled(uploadForm.values)}
                action={action}
              >
                Upload
              </AsyncButton>
            </ButtonToolbar>
          </Form>
        </div>
      </Modal>
    );
  }
}

const withForm = {
  form: 'uploadForm',
  initialValues: {
    category: { value: '1', label: 'Photo' },
  },
};


const mapStateToProps = (state) => {
  const {
    uploadForm,
  } = state.form;

  return {
    uploadForm,
  };
};


const mapDispatchToProps = {
  uploadFile,
  toggleUploadModal,
  selectFile,
};

export default wrapper({
  component: UploadModal,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(withForm),
  ],
});
