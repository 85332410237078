import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import { ButtonToolbar, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { faUpload, faTrash, faTh } from '@fortawesome/pro-solid-svg-icons';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import Select from '../../../shared/components/Select';

// Redux
import { getFile } from '../redux/actions';

// Utils
import getUserType from '../../../shared/utils/getUserType';


class Toolbar extends Component {
  static propTypes = {
    selected: PropTypes.instanceOf(Array).isRequired,
    viewer: PropTypes.bool.isRequired,

    // Redux
    get: PropTypes.func.isRequired,

    // Callbacks
    toggleUploadModal: PropTypes.func.isRequired,
    toggleEditModal: PropTypes.func.isRequired,
    toggleViewer: PropTypes.func.isRequired,
    deleteFiles: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      category: { value: '1', label: 'Photos' },
    };
  }

  handleOnCategoryChange = (value) => {
    const { get } = this.props;

    this.setState({
      category: value,
    }, () => {
      get({
        category: value.value,
      });
    });
  };

  render() {
    // State
    const {
      category,
    } = this.state;

    // Props
    const {
      selected,
      viewer,

      // Callbacks
      toggleUploadModal,
      toggleEditModal,
      toggleViewer,
      deleteFiles,
    } = this.props;

    const isAdmin = getUserType() === 'admin';

    return (
      <div className="mb-4 d-flex justify-content-between">
        <ButtonToolbar>
          <Button
            size="sm"
            className="btn btn-primary rounded"
            onClick={toggleUploadModal}
          >
            <FontAwesomeIcon icon={faUpload} fixedWidth /> Upload
          </Button>
          {isAdmin && (
            <>
              <Button
                size="sm"
                className="btn btn-secondary rounded"
                disabled={selected.length !== 1}
                onClick={toggleEditModal}
              >
                <FontAwesomeIcon icon={faEdit} fixedWidth /> Edit
              </Button>
              <Button
                size="sm"
                className="btn btn-secondary rounded"
                disabled={selected.length === 0}
                onClick={deleteFiles}
              >
                <FontAwesomeIcon icon={faTrash} fixedWidth /> Delete
              </Button>
            </>
          )}
          <Button
            size="sm"
            className="float-right rounded"
            onClick={toggleViewer}
          >
            <FontAwesomeIcon icon={viewer ? faTh : faBars} fixedWidth />
            {viewer ? ' Gallery' : ' Viewer'}
          </Button>
        </ButtonToolbar>
        <div className="text-left" style={{ width: 400 }}>
          <Select
            value={category}
            onChange={this.handleOnCategoryChange}
            options={[
              { value: '1', label: 'Photos' },
              { value: '2', label: 'Icons' },
              { value: '3', label: 'Illustrations' },
            ]}
          />
        </div>
      </div>
    );
  }
}


const mapDispatchToProps = {
  get: getFile,
};

export default connect(null, mapDispatchToProps)(Toolbar);
