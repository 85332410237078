import React, { PureComponent } from 'react';
import { reduxForm, Form, Field } from 'redux-form';

// Components
import TextField from '../../../shared/components/form/TextField';
import ImageSetField from '../../../shared/components/form/ImageSetField';
import FroalaEditorField from '../../../shared/components/form/FroalaEditor';


class EditorForm extends PureComponent {
  handleOnSubmit = (event) => {
    event.preventDefault();
  };

  render() {
    return (
      <Form className="material-form news__editor__form form--horizontal" onSubmit={this.handleOnSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Title</span>
          <Field
            className="material-form__field news__editor__form__title"
            name="title"
            component={TextField}
          />
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Images</span>
          <Field
            name="imageSet"
            component={ImageSetField}
          />
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Body</span>
          <Field
            className="pt-3"
            name="body"
            component={FroalaEditorField}
          />
        </div>
      </Form>
    );
  }
}


const withForm = {
  form: 'editorForm',
};


export default reduxForm(withForm)(EditorForm);
