import { create } from 'axios';
import { createAxiosInstance } from 'ggtmo-utils';


const axios = createAxiosInstance(create, {
  baseURL: `${process.env.REACT_APP_BASE_URL}/private/permissions/api/v1/`,
});


// GET
const getSubmissionGroup = params => axios.get('', { params });


// POST
const createSubmission = requestData => axios.post('', requestData);


// PUT
const updateSubmissionGroup = requestData => axios.put('', requestData);


export default {
  GET: {
    submissionGroup: getSubmissionGroup,
  },
  POST: {
    submission: createSubmission,
  },
  PUT: {
    submissionGroup: updateSubmissionGroup,
  },
};
