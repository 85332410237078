import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { imageUrl } from 'ggtmo-utils';

// Components
import { Button, ButtonToolbar } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import Modal from '../../../shared/components/Modal';
import AsyncButton from '../../../shared/components/AsyncButton';

// Media utils
import { imagesInUse } from '../utils';


class DeleteConfirmModal extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    action: PropTypes.instanceOf(Object).isRequired,
    images: PropTypes.instanceOf(Array).isRequired,
    selected: PropTypes.instanceOf(Array).isRequired,

    // Callbacks
    toggle: PropTypes.func.isRequired,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
    deleteAnyway: PropTypes.func.isRequired,
  };

  static defaultProps = {
    open: false,

    // Callbacks
    onOpened: () => {},
    onClosed: () => {},
  };

  render() {
    // Props
    const {
      open,
      action,
      images,
      selected,

      // Callbacks
      toggle,
      onOpened,
      onClosed,
      deleteAnyway,
    } = this.props;

    const singular = imagesInUse(images, selected).length === 1;

    return (
      <Modal
        open={open}
        width="xl"

        // Callbacks
        toggle={toggle}
        onOpened={onOpened}
        onClosed={onClosed}
      >
        <div className="modal__body gallery__delete-confirm">
          <h3 className="text-danger">
            <FontAwesomeIcon icon={faExclamationCircle} /> The following images are in use
          </h3>
          <hr />
          <table>
            <tbody>
              <tr>
                <th>Preview</th>
                <th>Name</th>
              </tr>
              {imagesInUse(images, selected).map(image => (
                <tr key={image.id}>
                  <td>
                    <img src={imageUrl(image.url)} alt="" className="shadow rounded" />
                  </td>
                  <td>
                    {image.name}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr />
          <h5 className="mb-5">
            You can still delete {singular ? 'this image' : 'these images'} if you wish
            but all instances (stories, events, etc...) will lose access to {singular ? 'it' : 'them'}. By deleting{' '}
            {singular ? 'an image' : 'images'} that {singular ? 'is' : 'are'} in use {singular ? 'it' : 'they'} will
            be removed from the public site.
          </h5>
          <ButtonToolbar className="justify-content-center">
            <AsyncButton action={action} className="rounded-0" color="danger" onClick={deleteAnyway}>
              Delete anyway
            </AsyncButton>
            <Button className="rounded-0" onClick={toggle}>Cancel</Button>
          </ButtonToolbar>
        </div>
      </Modal>
    );
  }
}

export default DeleteConfirmModal;
