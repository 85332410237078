import { generateActionCreator as generate, reduxTypes as types } from 'ggtmo-utils';


// Async
export const EVENTS_GET_EVENT = generate('EVENTS_GET_EVENT', { type: types.ASYNC });
export const EVENTS_CREATE_EVENT = generate('EVENTS_CREATE_EVENT', { type: types.ASYNC });
export const EVENTS_CREATE_AND_PUBLISH_EVENT = generate('EVENTS_CREATE_AND_PUBLISH_EVENT', { type: types.ASYNC });
export const EVENTS_UPDATE_EVENT = generate('EVENTS_UPDATE_EVENT', { type: types.ASYNC });
export const EVENTS_UPDATE_AND_PUBLISH_EVENT = generate('EVENTS_UPDATE_AND_PUBLISH_EVENT', { type: types.ASYNC });
export const EVENTS_DELETE_EVENT = generate('EVENTS_DELETE_EVENT', { type: types.ASYNC });
export const EVENTS_PUBLISH_EVENT = generate('EVENTS_PUBLISH_EVENT', { type: types.ASYNC });
export const EVENTS_UNPUBLISH_EVENT = generate('EVENTS_UNPUBLISH_EVENT', { type: types.ASYNC });
export const EVENTS_CONVERT_TO_STORY = generate('EVENTS_CONVERT_TO_STORY', { type: types.ASYNC });

// Sync
export const EVENTS_SELECT_EVENT = generate('EVENTS_SELECT_EVENT', { type: types.SYNC });
export const EVENTS_CLEAR_EDITOR = generate('EVENTS_CLEAR_EDITOR', { type: types.SYNC });
export const EVENTS_CHANGE_PAGE = generate('EVENTS_CHANGE_PAGE', { type: types.SYNC });
