import React from 'react';
import { render } from 'react-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import { setDefaultLocale } from 'react-datepicker';
import 'react-toastify/scss/main.scss';
import '@fortawesome/fontawesome-pro/css/all.css';
import '@fortawesome/fontawesome-pro/js/all';
import 'react-datepicker/dist/react-datepicker.min.css';
import 'react-image-gallery/styles/css/image-gallery.css';

// Froala
import './froala';

import App from './containers/App/App';

toast.configure({
  autoClose: 2000,
  hideProgressBar: true,
  pauseOnHover: false,
  pauseOnFocusLoss: false,
  position: 'top-center',
});

moment.locale('en-AU');

setDefaultLocale('en-AU');

render(
  <App />,
  document.getElementById('root'),
);
