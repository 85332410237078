/* eslint-disable max-len */
import { generateAction as generate, reduxTypes as types } from 'ggtmo-utils';
import service from '../service';

// Action Creators
import {
  // Async
  EVENTS_GET_EVENT,
  EVENTS_CREATE_EVENT,
  EVENTS_CREATE_AND_PUBLISH_EVENT,
  EVENTS_UPDATE_EVENT,
  EVENTS_UPDATE_AND_PUBLISH_EVENT,
  EVENTS_DELETE_EVENT,
  EVENTS_PUBLISH_EVENT,
  EVENTS_UNPUBLISH_EVENT,
  EVENTS_CONVERT_TO_STORY,

  // Sync
  EVENTS_SELECT_EVENT,
  EVENTS_CLEAR_EDITOR,
  EVENTS_CHANGE_PAGE,
} from './actionCreators';


// Async
export const getEvent = generate(EVENTS_GET_EVENT, { type: types.ASYNC, service: service.GET.getEvent });
export const createEvent = generate(EVENTS_CREATE_EVENT, { type: types.ASYNC, service: service.POST.createEvent });
export const createAndPublishEvent = generate(EVENTS_CREATE_AND_PUBLISH_EVENT, { type: types.ASYNC, service: service.POST.createAndPublishEvent });
export const updateEvent = generate(EVENTS_UPDATE_EVENT, { type: types.ASYNC, service: service.PUT.updateEvent });
export const updateAndPublishEvent = generate(EVENTS_UPDATE_AND_PUBLISH_EVENT, { type: types.ASYNC, service: service.PUT.updateAndPublishEvent });
export const deleteEvent = generate(EVENTS_DELETE_EVENT, { type: types.ASYNC, service: service.DELETE.deleteEvent });
export const publishEvent = generate(EVENTS_PUBLISH_EVENT, { type: types.ASYNC, service: service.PUT.publishEvent });
export const unpublishEvent = generate(EVENTS_UNPUBLISH_EVENT, { type: types.ASYNC, service: service.PUT.unpublishEvent });
export const convertToStory = generate(EVENTS_CONVERT_TO_STORY, { type: types.ASYNC, service: service.POST.convertEventToStory });


// Sync
export const selectEvent = generate(EVENTS_SELECT_EVENT, { type: types.SYNC });
export const clearEditor = generate(EVENTS_CLEAR_EDITOR, { type: types.SYNC });
export const changePage = generate(EVENTS_CHANGE_PAGE, { type: types.SYNC });
