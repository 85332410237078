import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { wrapper } from 'ggtmo-utils';

// Components
import { Container, Card, CardBody } from 'reactstrap';
import NewsTable from './components/NewsTable';
import Toolbar from './components/Toolbar';
import AsyncComponent from '../../shared/components/AsyncComponent';

// Redux
import {
  getStory,
  selectStories,
  deleteStory,
  changePage,
} from './redux/actions';
import {
  NEWS_DELETE_STORY,
} from './redux/actionCreators';

// Classes
import BaseEditorWrapper from '../../shared/BaseEditorWrapper';

// Images
import newsImage from '../../shared/img/news.png';

import generateTitle from '../../shared/utils/generateTitle';


class News extends BaseEditorWrapper {
  static propTypes = {
    // News
    newsActions: PropTypes.instanceOf(Object).isRequired,
    stories: PropTypes.instanceOf(Array).isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
  };

  appName = 'news';

  actionCreators = {
    delete: NEWS_DELETE_STORY,
  };

  toastMessages = {
    delete: {
      SUCCESS: length => `Successfully deleted ${length === 1 ? 'story' : 'stories'}.`,
    },
  };

  componentDidMount() {
    super.componentDidMount();
    document.title = generateTitle('News');
  }

  render() {
    // Props
    const {
      // News
      newsActions: { NEWS_GET_STORY: getStoryAction },
      stories,
      selected,
      page,
      count,
    } = this.props;

    return (
      <Container>
        <h3 className="page-title">News</h3>
        <p className="page-subhead">Add and edit news stories.</p>
        <Card style={{ minHeight: 753 }}>
          <CardBody>
            <Toolbar
              selected={selected}

              // Callbacks
              onCreate={this.handleOnCreate}
              onEdit={this.handleOnEdit}
              onDelete={this.handleOnDelete}
            />
            <AsyncComponent action={getStoryAction}>
              <NewsTable
                stories={stories}
                selected={selected}
                page={page}
                count={count}

                // Callbacks
                onSelect={this.handleOnSelect}
                onPageChange={this.handleOnPageChange}
              >
                <div className="editor__no-instances mt-5">
                  <h4>You&#39;ve not created any stories yet. Hit the create button to get started.</h4>
                  <img src={newsImage} alt="" />
                </div>
              </NewsTable>
            </AsyncComponent>
          </CardBody>
        </Card>
      </Container>
    );
  }
}


const mapStateToProps = (state) => {
  const {
    actions: newsActions,
    stories,
    selectedStories,
    page,
    count,
  } = state.news;

  return {
    // News
    newsActions,
    stories,
    selected: selectedStories,
    page,
    count,
  };
};


const mapDispatchToProps = {
  get: getStory,
  delete: deleteStory,
  select: selectStories,
  changePage,
};

export default wrapper({
  component: News,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
  ],
});
