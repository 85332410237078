import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { reduxForm } from 'redux-form';
import { wrapper } from 'ggtmo-utils';
import { mutateTree } from '@atlaskit/tree';
import { toast } from 'react-toastify';

// Components
import {
  Container, Row, Col, Card, CardBody,
} from 'reactstrap';
import SectionEditorForm from './EditorForm';
import AsyncComponent from '../../../shared/components/AsyncComponent';
import EditorControls from '../../../shared/components/EditorControls';
import DeleteConfirmModal from '../../../shared/components/DeleteConfirmModal';

// Redux
import {
  toggleDeleteConfirmModal,
} from '../../../redux/actions/editorActions';
import {
  // Async
  getSection,
  createSection,
  updateSection,
  updateAndPublishSection,
  publishSection,
  unpublishSection,
  deleteSection,
  getPage,

  // Sync
  clearEditor,
  updateKey,
} from '../redux/actions';
import {
  SECTIONS_GET_SECTION,
  SECTIONS_CREATE_SECTION,
  SECTIONS_UPDATE_SECTION,
  SECTIONS_UPDATE_AND_PUBLISH_SECTION,
  SECTIONS_PUBLISH_SECTION,
  SECTIONS_UNPUBLISH_SECTION,
  SECTIONS_DELETE_SECTION,
  SECTIONS_GET_PAGE,
} from '../redux/actionCreators';
import {
  selectFile,
  toggleMediaModal,
} from '../../Media/redux/actions';

// Classes
import BaseEditor from '../../../shared/BaseEditor';

// Utils
import getUserType from '../../../shared/utils/getUserType';

class Editor extends BaseEditor {
  static propTypes = {
    // Editor
    deleteConfirmModalOpen: PropTypes.bool.isRequired,

    // Sections
    sectionsActions: PropTypes.instanceOf(Object).isRequired,
    section: PropTypes.instanceOf(Object).isRequired,
    page: PropTypes.instanceOf(Object).isRequired,

    // Actions
    getSection: PropTypes.func.isRequired,
    toggleDocumentsModal: PropTypes.func.isRequired,

    // Form
    editorForm: PropTypes.instanceOf(Object),
    pristine: PropTypes.bool.isRequired,
    change: PropTypes.func.isRequired,
  };

  static defaultProps = {
    // Form
    editorForm: {},
  };

  appName = 'sections';

  redirectName = 'content';

  modelName = 'section';

  actionCreators = {
    getSection: SECTIONS_GET_SECTION,
    get: SECTIONS_GET_PAGE,
    create: SECTIONS_CREATE_SECTION,
    update: SECTIONS_UPDATE_SECTION,
    updateAndPublish: SECTIONS_UPDATE_AND_PUBLISH_SECTION,
    delete: SECTIONS_DELETE_SECTION,
    publish: SECTIONS_PUBLISH_SECTION,
    unpublish: SECTIONS_UNPUBLISH_SECTION,
  };

  toastMessages = {
    create: {
      SUCCESS: 'Created section.',
      ERROR: 'Error creating section.',
    },
    update: {
      SUCCESS: 'Saved section.',
      ERROR: 'Error saving section.',
    },
    updateAndPublish: {
      SUCCESS: 'Saved and published section. Your changes are visible to the public.',
      ERROR: 'Error saving section.',
    },
    delete: {
      SUCCESS: 'Deleted section,',
      ERROR: 'Error deleting section.',
    },
    publish: {
      SUCCESS: 'Published section. Your changes are visible to the public.',
      ERROR: 'Error publishing section.',
    },
    unpublish: {
      SUCCESS: 'Unpublished section. It is no longer visible to the public.',
      ERROR: 'Error unpublishing section.',
    },
    submit: {
      SUCCESS: 'Submitted for review. You will be notified if it is approved for publish.',
      ERROR: 'Error submitting for review.',
    },
  };

  extraRequestParameters = {
    create: {
      isChild: true,
    },
    update: {
      isChild: true,
    },
  };

  componentDidMount() {
    const { get, match: { params: { slug } } } = this.props;

    // Get the data for the instance being edited.
    get({
      list: false,
      tree: true,
      slug,
    });
  }

  handleOnEdit = (event) => {
    const { id } = event.currentTarget.dataset;
    const {
      getSection: get, change, initialize, updateKey: updateKeyAction,
    } = this.props;

    get({
      id,
      list: false,
    })
      .then(({ type, response }) => {
        if (type === SECTIONS_GET_SECTION.SUCCESS) {
          change('child', response.section);
          initialize(this._getFormValues());
          updateKeyAction({
            key: response.section.id,
          });
        }
      });
  };

  _changeTree = (value) => {
    const { change } = this.props;
    change('tree', value);
  };

  _initialize = (action) => {
    const { initialize } = this.props;
    const { section, sectionTreeItem, tree } = action.response;
    const formValues = this._getFormValues();
    initialize({
      ...formValues,
      child: {
        ...formValues.child,
        ...section,
      },
      tree: tree || mutateTree(formValues.tree, section.id, sectionTreeItem),
    });
  };

  handleOnUpdate = () => {
    const { update, page } = this.props;
    const formValues = this._getFormValues();
    return update({
      ...formValues,
      ...this.extraRequestParameters.update,
      pageSlug: page.slug,
      updateImageSet: !!formValues.imageSet,
      updateDocumentSet: !!formValues.documentSet,
    })
      .then((action) => {
        if (action.type === this.actionCreators.update.SUCCESS) {
          this._initialize(action);
        }
        this._onUpdatePromiseCallback(action, this.actionCreators.update, this.toastMessages.update);
      });
  };

  handleOnUpdateAndPublish = () => {
    const { updateAndPublish, page } = this.props;
    const formValues = this._getFormValues();
    return updateAndPublish({
      ...this._getFormValues(),
      ...this.extraRequestParameters.updateAndPublish,
      pageSlug: page.slug,
      updateImageSet: !!formValues.imageSet,
      updateDocumentSet: !!formValues.documentSet,
    })
      .then((action) => {
        if (action.type === this.actionCreators.updateAndPublish.SUCCESS) {
          this._initialize(action);
        }
        this._onUpdatePromiseCallback(action, this.actionCreators.updateAndPublish,
          this.toastMessages.updateAndPublish);
      });
  };

  handleOnPublish = () => {
    const { publish, page } = this.props;
    const formValues = this._getFormValues();

    return publish({
      pageSlug: page.slug,
      child: {
        id: formValues.child.id,
      },
    })
      .then((action) => {
        if (action.type === this.actionCreators.publish.SUCCESS) {
          this._initialize(action);
        }
        this._onUpdatePromiseCallback(action, this.actionCreators.publish, this.toastMessages.publish);
      });
  };

  handleOnUnpublish = () => {
    const { unpublish, page } = this.props;
    const formValues = this._getFormValues();

    return unpublish({
      pageSlug: page.slug,
      child: {
        id: formValues.child.id,
      },
    })
      .then((action) => {
        if (action.type === this.actionCreators.unpublish.SUCCESS) {
          this._initialize(action);
        }
        this._onUpdatePromiseCallback(action, this.actionCreators.unpublish, this.toastMessages.unpublish);
      });
  };

  handleOnDelete = () => {
    const {
      delete: delete_, toggleDeleteConfirmModal: toggle, page, clearEditor: clear,
    } = this.props;
    return delete_({
      pageSlug: page.slug,
      id: this._getFormValues().child.id,
    })
      .then(({ type, response, error }) => {
        if (type === this.actionCreators.delete.SUCCESS) {
          this._changeTree(response.tree);
          clear();
          toggle();
          toast(this.toastMessages.delete.SUCCESS, { type: toast.TYPE.SUCCESS });
        }
        if (type === this.actionCreators.delete.ERROR) {
          toast(this._parseErrorResponse(error, this.toastMessages.delete.ERROR),
            { type: toast.TYPE.ERROR, autoClose: 5000 });
        }
      });
  };

  _handleOnSubmit = (event) => {
    // This is just a placeholder to
    // stop the prop type warnings.
    event.preventDefault();
  };

  render() {
    // Props
    const {
      // Sections
      sectionsActions: {
        SECTIONS_GET_PAGE: getPageAction,
        SECTIONS_GET_SECTION: getSectionAction,
        SECTIONS_UPDATE_SECTION: updateSectionAction,
        SECTIONS_UPDATE_AND_PUBLISH_SECTION: updateAndPublishSectionAction,
        SECTIONS_PUBLISH_SECTION: publishSectionAction,
        SECTIONS_UNPUBLISH_SECTION: unpublishSectionAction,
        SECTIONS_DELETE_SECTION: deleteSectionAction,
      },
      section,

      // Redux
      page,
      deleteConfirmModalOpen,

      // Form
      pristine,
      editorForm,
    } = this.props;

    return (
      <>
        <Container>
          <Row>
            <Col xs={9}>
              <AsyncComponent action={getPageAction}>
                <SectionEditorForm
                  initialValues={{
                    tree: page.tree,
                  }}
                  enableReinitialize

                  // Actions
                  getSectionAction={getSectionAction}

                  // Callbacks
                  onEdit={this.handleOnEdit}
                  onSubmit={this._handleOnSubmit}
                />
              </AsyncComponent>
            </Col>
            {editorForm.values && editorForm.values.child && (
              <>
                <Col style={{ height: '100%' }}>
                  <Card>
                    <CardBody className="shadow">
                      <EditorControls
                        userType={getUserType()}
                        instanceType="section"
                        isNew={false}
                        pristine={pristine}
                        instance={section}

                        // Actions#
                        updateAction={updateSectionAction}
                        updateAndPublishAction={updateAndPublishSectionAction}
                        publishAction={publishSectionAction}
                        unpublishAction={unpublishSectionAction}
                        deleteAction={deleteSectionAction}

                        // Callbacks
                        onUpdate={this.handleOnUpdate}
                        onUpdateAndPublish={this.handleOnUpdateAndPublish}
                        onPublish={this.handleOnPublish}
                        onUnpublish={this.handleOnUnpublish}
                        onDelete={this.handleOnToggleDeleteConfirmModal}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </>
            )}
          </Row>
        </Container>
        <DeleteConfirmModal
          open={deleteConfirmModalOpen}
          instanceType="section"
          action={deleteSection}

          // Callbacks
          toggle={this.handleOnToggleDeleteConfirmModal}
          deleteAnyway={this.handleOnDelete}
        />
      </>
    );
  }
}


const mapStateToProps = (state) => {
  const {
    deleteConfirmModalOpen,
  } = state.editor;

  const {
    actions: sectionsActions,
    page,
    section,
  } = state.sections;

  const {
    editorForm,
  } = state.form;

  const {
    images,
    selectedFiles,
  } = state.media;

  return {
    // Editor
    deleteConfirmModalOpen,

    // Sections
    sectionsActions,
    page,
    section,

    // Form
    editorForm,

    // Media
    images,
    selectedFiles,
  };
};


const mapDispatchToProps = {
  // Async
  get: getPage,
  getSection,
  create: createSection,
  update: updateSection,
  updateAndPublish: updateAndPublishSection,
  delete: deleteSection,
  publish: publishSection,
  unpublish: unpublishSection,

  // Sync
  selectFile,
  toggleMediaModal,
  clearEditor,
  updateKey,
  toggleDeleteConfirmModal,
};


const withForm = {
  form: 'editorForm',
};

export default wrapper({
  component: Editor,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    reduxForm(withForm),
  ],
});
