import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { imageUrl } from 'ggtmo-utils';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

const ImageBody = ({ src }) => (
  <div>
    <img
      src={src}
      alt=""
      className="img-fluid rounded"
    />
  </div>
);

ImageBody.propTypes = {
  src: PropTypes.string.isRequired,
};


const ImageInfo = ({ name, date }) => (
  <div className="p-2 gallery__image-info">
    <p>{name}</p>
    <p className="text-black-50"><small>{moment(date).format('DD/MM/YY')}</small></p>
  </div>
);

ImageInfo.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};


class Image extends PureComponent {
  static propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    dateTimeUploaded: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    selected: PropTypes.instanceOf(Array).isRequired,
  };

  handleOnSelect = () => {
    const { id, onSelect, selected } = this.props;

    if (selected.includes(id)) {
      onSelect([...selected.filter(imageId => imageId !== id)]);
    } else {
      onSelect([...selected, id]);
    }
  };

  render() {
    const {
      id, name, dateTimeUploaded: date, url, selected,
    } = this.props;

    return (
      <div
        className="bg-white shadow rounded gallery__image"
        role="presentation"
        onClick={this.handleOnSelect}
      >
        {selected.includes(id) && (
          <div className="gallery__image__selected text-left">
            <FontAwesomeIcon icon={faCheck} fixedWidth /> Selected
          </div>
        )}
        <ImageBody src={imageUrl(url)} />
        <ImageInfo name={name} date={date} />
      </div>
    );
  }
}

export default Image;
