import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import { Container, Row, Col } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import Image from './Image';


const colDims = {
  xs: 6,
  sm: 4,
  md: 3,
  lg: 2,
  xl: 2,
};


class Gallery extends PureComponent {
  static propTypes = {
    images: PropTypes.instanceOf(Array).isRequired,
    selected: PropTypes.instanceOf(Array).isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,

    // Callbacks
    onSelect: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
  };

  handleChangePage = (event, page) => {
    const { onPageChange } = this.props;

    onPageChange(page);
  };

  render() {
    // Props
    const {
      images,
      selected,
      page,
      count,

      // Callbacks
      onSelect,
    } = this.props;

    return (
      <div>
        <Container>
          <Row>
            {images.map(image => (
              <Col {...colDims} className="mb-3 pl-0">
                <Image {...image} onSelect={onSelect} selected={selected} />
              </Col>
            ))}
          </Row>
          <TablePagination
            component="div"
            className="material-table__pagination"
            count={count}
            rowsPerPage={24}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous Page' }}
            nextIconButtonProps={{ 'aria-label': 'Next Page' }}
            onChangePage={this.handleChangePage}
            rowsPerPageOptions={[]}
          />
        </Container>
      </div>
    );
  }
}

export default Gallery;
