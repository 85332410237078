import {
  generateReducerElement as generate,
  reduxTypes as types,
  initializeActions,
} from 'ggtmo-utils';

// Action Creators
import {
  // Async
  EVENTS_GET_EVENT,
  EVENTS_CREATE_EVENT,
  EVENTS_CREATE_AND_PUBLISH_EVENT,
  EVENTS_UPDATE_EVENT,
  EVENTS_UPDATE_AND_PUBLISH_EVENT,
  EVENTS_DELETE_EVENT,
  EVENTS_PUBLISH_EVENT,
  EVENTS_UNPUBLISH_EVENT,

  // Sync
  EVENTS_CONVERT_TO_STORY,
  EVENTS_SELECT_EVENT,
  EVENTS_CLEAR_EDITOR,
  EVENTS_CHANGE_PAGE,
} from './actionCreators';


const initialState = {
  events: [],
  selectedEvents: [],
  event: {},
  page: 0,
  count: null,
  actions: initializeActions([
    EVENTS_GET_EVENT,
    EVENTS_CREATE_EVENT,
    EVENTS_CREATE_AND_PUBLISH_EVENT,
    EVENTS_UPDATE_EVENT,
    EVENTS_UPDATE_AND_PUBLISH_EVENT,
    EVENTS_DELETE_EVENT,
    EVENTS_PUBLISH_EVENT,
    EVENTS_UNPUBLISH_EVENT,
    EVENTS_CONVERT_TO_STORY,
  ]),
};


export default (state = initialState, action) => ({
  // Async
  ...generate(state, action, EVENTS_GET_EVENT, { type: types.ASYNC }),
  ...generate(state, action, EVENTS_CREATE_EVENT, { type: types.ASYNC }),
  ...generate(state, action, EVENTS_CREATE_AND_PUBLISH_EVENT, { type: types.ASYNC }),
  ...generate(state, action, EVENTS_UPDATE_EVENT, { type: types.ASYNC }),
  ...generate(state, action, EVENTS_UPDATE_AND_PUBLISH_EVENT, { type: types.ASYNC }),
  ...generate(state, action, EVENTS_DELETE_EVENT, { type: types.ASYNC }),
  ...generate(state, action, EVENTS_PUBLISH_EVENT, { type: types.ASYNC }),
  ...generate(state, action, EVENTS_UNPUBLISH_EVENT, { type: types.ASYNC }),
  ...generate(state, action, EVENTS_CONVERT_TO_STORY, { type: types.ASYNC }),

  // Sync
  ...generate(state, action, EVENTS_SELECT_EVENT, {
    type: types.SYNC,
    adjustment: {
      selectedEvents: action.selected,
    },
  }),
  ...generate(state, action, EVENTS_CLEAR_EDITOR, {
    type: types.SYNC,
    adjustment: {
      event: initialState.event,
    },
  }),
  ...generate(state, action, EVENTS_CHANGE_PAGE, {
    type: types.SYNC,
    adjustment: {
      page: action.page,
    },
  }),
}[action.type] || state);
