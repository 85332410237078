/* eslint-disable max-len */
import { generateAction as generate, reduxTypes as types } from 'ggtmo-utils';
import service from '../service';

// Action Creators
import {
  // Async
  SECTIONS_GET_SECTION,
  SECTIONS_LIST_SECTIONS,
  SECTIONS_CREATE_SECTION,
  SECTIONS_CREATE_AND_PUBLISH_SECTION,
  SECTIONS_UPDATE_SECTION,
  SECTIONS_UPDATE_AND_PUBLISH_SECTION,
  SECTIONS_DELETE_SECTION,
  SECTIONS_PUBLISH_SECTION,
  SECTIONS_UNPUBLISH_SECTION,

  // Sync
  SECTIONS_SELECT_SECTION,
  SECTIONS_CLEAR_EDITOR,
  SECTIONS_UPDATE_KEY,

  // Page
  SECTIONS_GET_PAGE,
  SECTIONS_LIST_PAGES,
  SECTIONS_UPDATE_PAGE,
} from './actionCreators';


// Async - sections
export const getSection = generate(SECTIONS_GET_SECTION, { type: types.ASYNC, service: service.GET.getSection });
export const listSections = generate(SECTIONS_LIST_SECTIONS, { type: types.ASYNC, service: service.GET.listSections });
export const createSection = generate(SECTIONS_CREATE_SECTION, { type: types.ASYNC, service: service.POST.createSection });
export const createAndPublishSection = generate(SECTIONS_CREATE_AND_PUBLISH_SECTION, { type: types.ASYNC, service: service.POST.createAndPublishSection });
export const updateSection = generate(SECTIONS_UPDATE_SECTION, { type: types.ASYNC, service: service.PUT.updateSection });
export const updateAndPublishSection = generate(SECTIONS_UPDATE_AND_PUBLISH_SECTION, { type: types.ASYNC, service: service.PUT.updateAndPublishSection });
export const deleteSection = generate(SECTIONS_DELETE_SECTION, { type: types.ASYNC, service: service.DELETE.deleteSection });
export const publishSection = generate(SECTIONS_PUBLISH_SECTION, { type: types.ASYNC, service: service.PUT.publishSection });
export const unpublishSection = generate(SECTIONS_UNPUBLISH_SECTION, { type: types.ASYNC, service: service.PUT.unpublishSection });

// Async - pages
export const getPage = generate(SECTIONS_GET_PAGE, { type: types.ASYNC, service: service.GET.getPage });
export const listPages = generate(SECTIONS_LIST_PAGES, { type: types.ASYNC, service: service.GET.listPages });
export const updatePage = generate(SECTIONS_UPDATE_PAGE, { type: types.ASYNC, service: service.PUT.updatePage });

// Sync
export const selectSection = generate(SECTIONS_SELECT_SECTION, { type: types.SYNC });
export const clearEditor = generate(SECTIONS_CLEAR_EDITOR, { type: types.SYNC });
export const updateKey = generate(SECTIONS_UPDATE_KEY, { type: types.SYNC });
