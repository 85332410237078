import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';
import { toast } from 'react-toastify';
import { wrapper, imageUrl } from 'ggtmo-utils';

// Components
import { Button, ButtonToolbar } from 'reactstrap';
import TextField from '../../../shared/components/form/TextField';
import Modal from '../../../shared/components/Modal';
import AsyncButton from '../../../shared/components/AsyncButton';

// Redux
import {
  updateFile,
  selectFile,
  toggleEditModal,
} from '../redux/actions';
import {
  MEDIA_UPDATE_FILE,
} from '../redux/actionCreators';
import Select from '../../../shared/components/form/Select';


class UploadModal extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    action: PropTypes.instanceOf(Object).isRequired,

    // Actions
    updateFile: PropTypes.func.isRequired,
    selectFile: PropTypes.func.isRequired,
    toggleEditModal: PropTypes.func.isRequired,

    // Form
    editForm: PropTypes.instanceOf(Object),

    // Media
    images: PropTypes.instanceOf(Array).isRequired,
    selectedFiles: PropTypes.instanceOf(Array).isRequired,

    // Callbacks
    toggle: PropTypes.func.isRequired,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
  };

  static defaultProps = {
    open: false,

    // Form
    editForm: {
      values: {
        name: '',
      },
    },

    // Callbacks
    onOpened: () => {},
    onClosed: () => {},
  };

  isDisabled = (values) => {
    if (!values) return true;

    return values.name.length === 0;
  };

  handleOnSubmit = (event) => {
    event.preventDefault();
    const {
      editForm, updateFile: update, toggleEditModal: toggle, selectFile: select,
    } = this.props;

    update({
      ...editForm.values,
      category: editForm.values.category.value,
    })
      .then((action) => {
        if (action.type === MEDIA_UPDATE_FILE.SUCCESS) {
          toggle();
          select({ selected: [] });

          toast('Successfully edited file.', {
            type: toast.TYPE.SUCCESS,
          });
        }
      });
  };

  render() {
    // Props
    const {
      open,
      action,

      // Redux
      images,
      selectedFiles,

      // Form
      editForm,

      // Callbacks
      toggle,
      onOpened,
      onClosed,
    } = this.props;

    const instance = images.filter(f => f.id === parseInt(selectedFiles[0], 10))[0];

    return (
      <Modal
        open={open}
        width="xl"

        // Callbacks
        toggle={toggle}
        onOpened={onOpened}
        onClosed={onClosed}
      >
        <div className="modal__body">
          {instance && (
            <img
              src={imageUrl(instance.url)}
              alt=""
              style={{ maxHeight: 400, objectFit: 'cover' }}
            />
          )}
          <Form className="material-form mt-4 text-left" onSubmit={this.handleOnSubmit}>
            <div className="form__form-group">
              <span className="material-form__label float-left">Name</span>
              <Field
                className="material-form__field"
                name="name"
                component={TextField}
              />
            </div>
            <div className="form__form-group">
              <span className="material-form__label text-left">Category</span>
              <div className="mt-2">
                <Field
                  className="material-form__field"
                  name="category"
                  component={Select}
                  options={[
                    { value: '1', label: 'Photo' },
                    { value: '2', label: 'Icon' },
                    { value: '3', label: 'Illustration' },
                  ]}
                />
              </div>
            </div>
            <ButtonToolbar className="modal__footer">
              <Button onClick={toggle}>Cancel</Button>{' '}
              <AsyncButton
                color="primary"
                type="submit"
                disabled={this.isDisabled(editForm.values)}
                action={action}
              >
                Save
              </AsyncButton>
            </ButtonToolbar>
          </Form>
        </div>
      </Modal>
    );
  }
}


const withForm = {
  form: 'editForm',
};


const mapStateToProps = (state) => {
  const {
    images,
    selectedFiles,
  } = state.media;

  const {
    editForm,
  } = state.form;

  return {
    selectedFiles,
    images,
    editForm,
  };
};


const mapDispatchToProps = {
  updateFile,
  selectFile,
  toggleEditModal,
};

export default wrapper({
  component: UploadModal,
  wrappers: [
    reduxForm(withForm),
    connect(mapStateToProps, mapDispatchToProps),
  ],
});
