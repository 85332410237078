import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { squashString } from 'ggtmo-utils';

// Components
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faRandom, faTimes } from '@fortawesome/pro-light-svg-icons';
import TableCell from '@material-ui/core/TableCell';
import MatTable from '../../../shared/components/table/MatTable';


const tableColumns = [
  {
    id: 'title', label: 'Title', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'isLive', label: 'Live', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'dateCreated', label: 'Date Created', disablePadding: false, numeric: false, align: 'left',
  },
];


const TableRow = ({
  id,
  title,
  isLive,
  dateTimeCreated,
  convertedToStory,
}) => (
  <>
    <TableCell className="material-table__cell">
      {squashString(title, { length: 80 })}
      {convertedToStory && (
        <>
          <Link to={`/news/editor/${convertedToStory.id}`}>
            <span className="float-right text-primary" id={`converted-to-story-${id}`}>
              <FontAwesomeIcon icon={faRandom} fixedWidth />
              {' '}Converted to a story
            </span>
          </Link>
          <UncontrolledTooltip target={`converted-to-story-${id}`} placement="top">
            This event was converted to a story.
          </UncontrolledTooltip>
        </>
      )}
    </TableCell>
    <TableCell className="material-table__cell">
      {isLive ? (
        <FontAwesomeIcon icon={faCheck} className="text-primary" fixedWidth />
      ) : (
        <FontAwesomeIcon icon={faTimes} className="text-danger" fixedWidth />
      )}
    </TableCell>
    <TableCell className="material-table__cell">{moment(dateTimeCreated).format('DD/MM/YYYY')}</TableCell>
  </>
);

TableRow.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isLive: PropTypes.bool.isRequired,
  dateTimeCreated: PropTypes.string.isRequired,
  convertedToStory: PropTypes.bool.isRequired,
};

const EventTable = ({
  children,
  events,
  selected,
  page,
  count,

  // Callbacks
  onSelect,
  onPageChange,
}) => (
  <div>
    <MatTable
      rowComponent={TableRow}
      columns={tableColumns}
      data={events}
      selected={selected}
      page={page}
      count={count}

      // Callbacks
      onSelect={onSelect}
      onPageChange={onPageChange}
    >
      {children}
    </MatTable>
  </div>
);

EventTable.propTypes = {
  children: PropTypes.node,
  events: PropTypes.instanceOf(Array).isRequired,
  selected: PropTypes.instanceOf(Array).isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,

  // Callbacks
  onSelect: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

EventTable.defaultProps = {
  children: null,
};

export default EventTable;
