import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { toast } from 'react-toastify';


class BaseEditorWrapper extends PureComponent {
  static propTypes = {
    selected: PropTypes.instanceOf(Array).isRequired,

    // Actions
    get: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    select: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,

    // Router
    history: RouterPropTypes.history.isRequired,
  };

  appName = '';

  actionCreators = {
    get: undefined,
    post: undefined,
    put: undefined,
    delete: undefined,
  };

  toastMessages = {
    get: undefined,
    post: undefined,
    put: undefined,
    delete: undefined,
  };

  componentDidMount() {
    const { get } = this.props;

    // Get the data for the table.
    get({
      list: true,
    });
  }

  componentWillUnmount() {
    const { select } = this.props;

    // Unselect all from the table.
    select({ selected: [] });
  }

  handleOnCreate = () => {
    const { history } = this.props;

    // Redirect to the editor.
    history.push(`/${this.appName}/editor/new`);
  };

  handleOnEdit = () => {
    const { selected: [id], history } = this.props;

    // Redirect to the editor.
    history.push(`/${this.appName}/editor/${id}`);
  };

  handleOnDelete = () => {
    const { delete: delete_, selected, select } = this.props;
    const { delete: deleteActionCreator } = this.actionCreators;
    const { delete: deleteToastMessages } = this.toastMessages;

    // Delete selected instances.
    delete_({ ids: selected })
      .then(({ type }) => {
        if (type === deleteActionCreator.SUCCESS) {
          toast(deleteToastMessages.SUCCESS(selected), {
            type: toast.TYPE.SUCCESS,
          });

          select({ selected: [] });
        }
      });
  };

  handleOnSelect = (selected) => {
    const { select } = this.props;

    // Update the selected array.
    select({ selected });
  };

  handleOnPageChange = (page) => {
    const { get, changePage } = this.props;

    // Get the data for the table.
    changePage({ page });
    get({
      list: true,
      page,
    });
  };
}

export default BaseEditorWrapper;
