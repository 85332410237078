import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';

// Components
import { Container, Card, CardBody } from 'reactstrap';
import DocumentsTable from './components/DocumentsTable';
import DocumentsToolbar from './components/DocumentsToolbar';
import UploadFileModal from './components/UploadFileModal';
import CreateFolderModal from './components/CreateFolderModal';
import EditDocumentModal from './components/EditDocumentModal';

// Redux
import {
  getDocument,
  deleteDocument,
  selectDocument,
  toggleCreateFileModal,
  toggleCreateFolderModal,
  toggleEditDocumentModal,
  changePage,
} from './redux/actions';
import AsyncComponent from '../../shared/components/AsyncComponent';
import { DOCUMENTS_DELETE_DOCUMENT } from './redux/actionCreators';

// Images
import documentsImage from '../../shared/img/documents.png';

import generateTitle from '../../shared/utils/generateTitle';

const BreadcrumbItem = styled.li`
  display: inline;

  span {
    font-size: 18px;
    font-weight: 500;
    color: #646777;
    
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

`;


const Breadcrumb = styled.ul`
  list-style: none;
  
  & ${BreadcrumbItem}+${BreadcrumbItem}:before {
    content: '/';
    color: rgba(0, 0, 0, 0.6);
    padding: 0 8px;
    font-size: 18px;
    text-decoration: none;
  }
`;


class Documents extends PureComponent {
  static propTypes = {
    inModal: PropTypes.bool,

    // Actions
    getDocument: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    selectDocument: PropTypes.func.isRequired,
    toggleCreateFileModal: PropTypes.func.isRequired,
    toggleCreateFolderModal: PropTypes.bool.isRequired,
    toggleEditDocumentModal: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,

    // Documents
    documentsActions: PropTypes.instanceOf(Object).isRequired,
    documents: PropTypes.instanceOf(Array).isRequired,
    document: PropTypes.instanceOf(Object).isRequired,
    documentCount: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    selectedDocuments: PropTypes.instanceOf(Array).isRequired,
    uploadFileModalOpen: PropTypes.bool.isRequired,
    createFolderModalOpen: PropTypes.bool.isRequired,
    editDocumentModalOpen: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    inModal: false,
  };

  componentDidMount() {
    // Get the top level documents (mostly folders).
    const { getDocument: get } = this.props;
    document.title = generateTitle('Documents');

    get({
      list: true,
    });
  }

  handleToggleCreateFileModal = () => {
    const { toggleCreateFileModal: toggle } = this.props;

    toggle();
  };

  handleToggleCreateFolderModal = () => {
    const { toggleCreateFolderModal: toggle } = this.props;

    toggle();
  };

  handleToggleDocumentEditModal = () => {
    const { toggleEditDocumentModal: toggle } = this.props;

    toggle();
  };

  handleOnSelectDocument = (selected) => {
    const { selectDocument: select } = this.props;

    select({ selected });
  };

  handleOnSelectDocumentByRow = (event, id) => {
    const { getDocument: get } = this.props;

    get({
      list: false,
      id,
    });
  };

  handleOnPageChange = (page) => {
    const { getDocument: get, changePage: change } = this.props;

    change({ page });
    get({
      list: true,
      page,
    });
  };

  handleOnBreadcrumbClick = (event) => {
    const { id } = event.currentTarget.dataset;
    const { getDocument: get } = this.props;

    if (id === 'top-level') {
      get({
        list: true,
      });
    } else {
      get({
        list: false,
        id,
      });
    }
  };

  handleOnDelete = () => {
    const { deleteDocument: delete_, selectedDocuments } = this.props;

    delete_({
      ids: selectedDocuments,
    })
      .then((action) => {
        if (action.type === DOCUMENTS_DELETE_DOCUMENT.SUCCESS) {
          toast(`Successfully deleted document${selectedDocuments.length === 1 ? '' : 's'}.`, {
            type: toast.TYPE.SUCCESS,
          });
        }
      });
  };

  render() {
    // Props
    const {
      inModal,

      // Documents
      documentsActions: {
        DOCUMENTS_GET_DOCUMENT,
      },
      documents,
      document,
      documentCount,
      page,
      selectedDocuments,
      uploadFileModalOpen,
      createFolderModalOpen,
      editDocumentModalOpen,
    } = this.props;

    return (
      <>
        <Container>
          {!inModal && (
            <>
              <h3 className="page-title">Documents</h3>
              <p className="page-subhead">Upload and manage documents.</p>
            </>
          )}
          <Card style={{ minHeight: 753 }}>
            <CardBody>
              <DocumentsToolbar
                selected={selectedDocuments}

                // Callbacks
                onUploadFile={this.handleToggleCreateFileModal}
                onCreateFolder={this.handleToggleCreateFolderModal}
                onEdit={this.handleToggleDocumentEditModal}
                onDelete={this.handleOnDelete}
              />
              {document.breadcrumb && (
                <Breadcrumb className="p-2 text-left">
                  <BreadcrumbItem
                    onClick={this.handleOnBreadcrumbClick}
                    data-id="top-level"
                  >
                    <span>Documents</span>
                  </BreadcrumbItem>
                  {document.breadcrumb.map(item => (
                    <BreadcrumbItem
                      onClick={this.handleOnBreadcrumbClick}
                      data-id={item.id}
                    >
                      <span>{item.label}</span>
                    </BreadcrumbItem>
                  ))}
                </Breadcrumb>
              )}
              <AsyncComponent action={DOCUMENTS_GET_DOCUMENT} onlyOnInitialLoad>
                <DocumentsTable
                  documents={documents}
                  selected={selectedDocuments}
                  count={documentCount}
                  page={page}

                  // Callbacks
                  onSelect={this.handleOnSelectDocument}
                  onSelectByRow={this.handleOnSelectDocumentByRow}
                  onPageChange={this.handleOnPageChange}
                >
                  <div className="editor__no-instances mt-5">
                    <h4>This folder is empty. Upload a file or create a folder to get started.</h4>
                    <img src={documentsImage} alt="" style={{ width: '30%' }} />
                  </div>
                </DocumentsTable>
              </AsyncComponent>
            </CardBody>
          </Card>
        </Container>
        <UploadFileModal
          open={uploadFileModalOpen}

          // Callbacks
          toggle={this.handleToggleCreateFileModal}
        />
        <CreateFolderModal
          open={createFolderModalOpen}

          // Callbacks
          toggle={this.handleToggleCreateFolderModal}
        />
        <EditDocumentModal
          open={editDocumentModalOpen}
          initialValues={documents.filter(d => d.id === selectedDocuments[0])[0]}
          enableReinitialize

          // Callbacks
          toggle={this.handleToggleDocumentEditModal}
        />
      </>
    );
  }
}


const mapStateToProps = (state) => {
  const {
    actions: documentsActions,
    documents,
    document,
    documentCount,
    page,
    selectedDocuments,
    uploadFileModalOpen,
    createFolderModalOpen,
    editDocumentModalOpen,
  } = state.documents;

  return {
    // Documents
    documentsActions,
    documents,
    document,
    documentCount,
    page,
    selectedDocuments,
    uploadFileModalOpen,
    createFolderModalOpen,
    editDocumentModalOpen,
  };
};


const mapDispatchToProps = {
  getDocument,
  deleteDocument,
  selectDocument,
  toggleCreateFileModal,
  toggleCreateFolderModal,
  toggleEditDocumentModal,
  changePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
