import { create } from 'axios';
import { createAxiosInstance } from 'ggtmo-utils';


const axios = createAxiosInstance(create, {
  baseURL: `${process.env.REACT_APP_BASE_URL}/integrations/api/v1/`,
});


// GET
const getIntegration = params => axios.get('', { params });
const twitterEnable = params => axios.get('twitter/enable/', { params });


// POST
const twitterAuthenticationSuccess = requestData => axios.post('twitter/authentication_success/', requestData);


export default {
  GET: {
    integration: getIntegration,
  },
  twitter: {
    GET: {
      enable: twitterEnable,
    },
    POST: {
      authenticationSuccess: twitterAuthenticationSuccess,
    },
  },
};
