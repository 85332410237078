import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import {
  Container, Row, Col, Card, CardBody, // Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import AsyncComponent from '../../shared/components/AsyncComponent';

// Redux
import { getPage } from './redux/actions';

import generateTitle from '../../shared/utils/generateTitle';

class Sections extends PureComponent {
  static propTypes = {
    // Redux
    sectionsActions: PropTypes.instanceOf(Object).isRequired,
    pages: PropTypes.instanceOf(Array).isRequired,

    // Actions
    get: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { get } = this.props;
    document.title = generateTitle('Content');

    get({
      list: true,
    });
  }

  render() {
    // Props
    const {
      sectionsActions: { SECTIONS_GET_PAGE: getPageAction },
      pages,
    } = this.props;

    return (
      <Container>
        <h3 className="page-title">Content</h3>
        <p className="page-subhead">Edit the site&#39;s content pages.</p>
        <hr />
        <AsyncComponent action={getPageAction}>
          <Row>
            {pages.map(page => (
              <Col xs={12} sm={6} lg={4} xl={2} key={page.slug}>
                <Link to={`/content/editor/${page.slug}`}>
                  <Card>
                    <CardBody className="text-center">
                      <h4>{page.title}</h4>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </AsyncComponent>
      </Container>
    );
  }
}


const mapStateToProps = (state) => {
  const {
    actions: sectionsActions,
    pages,
  } = state.sections;

  return {
    sectionsActions,
    pages,
  };
};


const mapDispatchToProps = {
  get: getPage,
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Sections);
