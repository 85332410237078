/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';


class DropZoneMultipleField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
      })),
    ]).isRequired,
    accept: PropTypes.string,
  };

  static defaultProps = {
    accept: 'image/jpeg, image/png',
  };

  constructor() {
    super();
    this.state = {};
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(files) {
    const { onChange } = this.props;
    onChange(files.map(fl => Object.assign(fl, {
      preview: URL.createObjectURL(fl),
    })));
  }

  removeFile = (index, e) => {
    const { value, onChange } = this.props;
    e.preventDefault();
    onChange(value.filter((val, i) => i !== index));
  };

  render() {
    const { name, value, accept } = this.props;
    const files = value;

    return (
      <div className="dropzone dropzone--multiple">
        <Dropzone
          className="dropzone__input"
          accept={accept}
          name={name}
          onDrop={(filesToUpload) => {
            this.onDrop(value ? value.concat(filesToUpload) : filesToUpload);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="dropzone__input">
              {(!files || files.length === 0)
              && (
                <div className="dropzone__drop-here">
                  <FontAwesomeIcon icon={faUpload} /> Drop file(s) here to upload, or click here to open a file explorer
                </div>
              )}
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
        {files && Array.isArray(files) && (
          <div className="dropzone__imgs-wrapper">
            {files.map((file, i) => (
              <div className="dropzone__img text-center" key={i} style={{ backgroundImage: `url(${file.preview})` }}>
                <p className="dropzone__img-name">{file.name}</p>
                <button className="dropzone__img-delete" type="button" onClick={e => this.removeFile(i, e)}>
                  <FontAwesomeIcon icon={faTimes} /> Remove
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

const renderDropZoneMultipleField = (props) => {
  const { input, ...rest } = props;
  return (
    <DropZoneMultipleField {...input} {...rest} />
  );
};

renderDropZoneMultipleField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
};

export default renderDropZoneMultipleField;
