import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { wrapper } from 'ggtmo-utils';

// Components
import RegisterForm from './components/RegisterForm';
import AsyncRegisterFormErrors from './components/AsyncRegisterFormErrors';

// Images
import logo from '../../../shared/img/logo/logo_light.png';

// Redux
import {
  register,
} from '../redux/actions';
import {
  ACCOUNT_REGISTER,
} from '../redux/actionCreators';


class Register extends PureComponent {
  static propTypes = {
    // Redux
    register: PropTypes.func.isRequired,
    action: PropTypes.instanceOf(Object).isRequired,

    // Form
    registerForm: PropTypes.instanceOf(Object),

    // Router
    history: ReactRouterPropTypes.history.isRequired,
  };

  static defaultProps = {
    // Form
    registerForm: {},
  };

  register = (event) => {
    event.preventDefault();

    // TODO: Think about the naming here.
    const { registerForm, register: registerUser, history } = this.props;

    registerUser(registerForm.values)
      .then((action) => {
        if (action.type === ACCOUNT_REGISTER.SUCCESS) {
          history.push('/verify-email/');
        }
      });
  };

  render() {
    // Props
    const {
      action,
    } = this.props;

    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card shadow">
            <img src={logo} alt="" className="mb-4" />
            <div className="text-danger my-2">
              <AsyncRegisterFormErrors action={action} />
            </div>
            <RegisterForm
              handleSubmit={this.register}
              action={action}
            />
            <div className="account__have-account">
              <p>Already have an account? <Link to="/login">Login</Link></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions: { ACCOUNT_REGISTER: action },
  } = state.account;
  const {
    registerForm,
  } = state.form;

  return {
    action,
    registerForm,
  };
};


const mapDispatchToProps = {
  register,
};


export default wrapper({
  component: Register,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
  ],
});
