import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tree, { mutateTree, moveItemOnTree } from '@atlaskit/tree';
import classNames from 'classnames';
import { squashString } from 'ggtmo-utils';

// Components
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faPlus, faTimes, faChevronRight, faChevronDown, faArrows,
} from '@fortawesome/pro-light-svg-icons';

// Redux
import { createSection, updatePage } from '../redux/actions';
import { SECTIONS_CREATE_SECTION } from '../redux/actionCreators';


class SideMenuOrderField extends Component {
  static propTypes = {
    // Form
    value: PropTypes.instanceOf(Object),
    onChange: PropTypes.func.isRequired,

    // Redux
    createSection: PropTypes.func.isRequired,
    updatePage: PropTypes.func.isRequired,
    sectionsActions: PropTypes.instanceOf(Object).isRequired,
    page: PropTypes.instanceOf(Object).isRequired,

    // Callbacks
    onEdit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    // Form
    value: {
      rootId: null,
      items: {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      numAdded: 0,
    };
  }

  renderItem = ({
    item,
    depth,
    provided: { innerRef, draggableProps, dragHandleProps },
  }) => {
    const { onEdit, value } = this.props;
    const onCollapse = () => this.onCollapse(item.id);
    const onExpand = () => this.onExpand(item.id);

    return (
      <div
        ref={innerRef}
        {...draggableProps}
        className={classNames('sections__editor__form__tree-item p-1 rounded shadow-sm', {
          top: depth === 0,
          first: value.items[value.rootId].children[0] === item.id,
        })}
      >
        <div className="d-flex justify-content-between">
          <div>
            {depth === 0 ? (
              <Button
                onClick={
                  item.isExpanded
                    ? onCollapse
                    : onExpand
                }
                className="btn-link-style mr-1"
              >
                <FontAwesomeIcon fixedWidth icon={item.isExpanded ? faChevronDown : faChevronRight} />
              </Button>
            ) : (
              <div style={{ width: depth === 0 ? 22.5 : 32, height: 0, display: 'inline-block' }} />
            )}
            <Button
              className="btn-link-style"
              onClick={onEdit}
              data-id={item.data.id}
            >
              <p style={{ lineHeight: '30px' }}>{squashString(item.data.title, { length: depth === 0 ? 15 : 20 })}</p>
            </Button>
          </div>
          <span {...dragHandleProps} style={{ lineHeight: '30px' }}>
            <FontAwesomeIcon
              className={classNames('my-auto mr-1', {
                'text-primary': item.data.isLive,
                'text-danger': !item.data.isLive,
              })}
              icon={item.data.isLive ? faCheck : faTimes}
              fixedWidth
            />
            <FontAwesomeIcon
              className="my-auto text-black-50"
              icon={faArrows}
              fixedWidth
            />
          </span>
        </div>
        {(item.isExpanded && depth === 0) && (
          <div className="py-2 text-black-50">
            <Button
              className="btn-link-style"
              onClick={this.onAdd}
              data-type="subsection"
              data-id={item.id}
              style={{ marginLeft: 11 }}
            >
              <FontAwesomeIcon icon={faPlus} fixedWidth />{' '}
              Add subsection
            </Button>
          </div>
        )}
      </div>
    );
  };

  onExpand = (itemId) => {
    const { value, onChange } = this.props;

    onChange(mutateTree(value, itemId, { isExpanded: true }));
  };

  onCollapse = (itemId) => {
    const { value, onChange } = this.props;

    onChange(mutateTree(value, itemId, { isExpanded: false }));
  };

  onDragEnd = (source, destination) => {
    const { value, onChange, updatePage: update } = this.props;

    if (!destination) {
      return;
    }

    const newTree = moveItemOnTree(value, source, destination);
    onChange(newTree);
    update({
      slug: value.rootId,
      tree: newTree,
    });
  };

  onAdd = (event) => {
    const { type: addType, id: parentId } = event.currentTarget.dataset;
    const { numAdded } = this.state;
    const { value, onChange, createSection: create } = this.props;

    create({
      rootId: value.rootId,
      title: `New ${addType} ${numAdded + 1}`,
      createType: addType,
      parentId,
    })
      .then(({ type, response: { sectionTreeItem } }) => {
        if (type === SECTIONS_CREATE_SECTION.SUCCESS) {
          const { id } = sectionTreeItem;
          let newTree;
          if (value.items) {
            const itemToMutateId = addType === 'section' ? value.rootId : parentId;
            newTree = mutateTree(value, itemToMutateId, {
              children: [
                ...value.items[itemToMutateId].children,
                id,
              ],
            });
          } else {
            newTree = {
              items: {
                [id]: {
                  ...sectionTreeItem,
                },
              },
            };
          }

          this.setState(prevState => ({
            numAdded: prevState.numAdded + 1,
          }));

          onChange({
            ...newTree,
            items: {
              ...newTree.items,
              [id]: {
                ...sectionTreeItem,
              },
            },
          });
        }
      });
  };

  render() {
    // State
    const {
      // Form
      value,

      // Redux
      page,
    } = this.props;

    return (
      <div className="sections__editor__form__sidemenu">
        <h3>
          {page.title}
        </h3>
        <hr />
        {value.items && (
          <Tree
            tree={value}
            renderItem={this.renderItem}
            onExpand={this.onExpand}
            onCollapse={this.onCollapse}
            onDragEnd={this.onDragEnd}
            isDragEnabled
            offsetPerLevel={30}
            className="pb-5"
          />
        )}
        <div className="mt-5">
          <Button
            className="btn-link-style"
            onClick={this.onAdd}
            data-type="section"
            icon={faPlus}
          >
            <FontAwesomeIcon icon={faPlus} fixedWidth />{' '}
            Add section
          </Button>
        </div>
      </div>
    );
  }
}


const renderSideMenuOrderField = ({ input, ...rest }) => (
  <SideMenuOrderField {...input} {...rest} />
);

renderSideMenuOrderField.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
};


const mapStateToProps = (state) => {
  const {
    actions: sectionsActions,
    page,
  } = state.sections;

  return {
    // Sections
    sectionsActions,
    page,
  };
};


const mapDispatchToProps = {
  createSection,
  updatePage,
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(renderSideMenuOrderField);
