import {
  generateReducerElement as generate, reduxTypes as types, initializeActions, adjust,
} from 'ggtmo-utils';

// Action Creators
import {
  DOCUMENTS_GET_DOCUMENT,
  DOCUMENTS_CREATE_DOCUMENT,
  DOCUMENTS_UPDATE_DOCUMENT,
  DOCUMENTS_DELETE_DOCUMENT,
  DOCUMENTS_SELECT_DOCUMENT,
  DOCUMENTS_TOGGLE_CREATE_FILE_MODAL,
  DOCUMENTS_TOGGLE_CREATE_FOLDER_MODAL,
  DOCUMENTS_TOGGLE_EDIT_DOCUMENT_MODAL,
  DOCUMENTS_CHANGE_PAGE,
  DOCUMENTS_TOGGLE_DOCUMENTS_MODAL,
} from './actionCreators';


const initialState = {
  documents: [],
  document: {},
  selectedDocuments: [],
  uploadFileModalOpen: false,
  createFolderModalOpen: false,
  editDocumentModalOpen: false,
  documentCount: 0,
  page: 0,
  documentsModalOpen: false,
  actions: initializeActions([
    DOCUMENTS_GET_DOCUMENT,
    DOCUMENTS_CREATE_DOCUMENT,
    DOCUMENTS_UPDATE_DOCUMENT,
    DOCUMENTS_DELETE_DOCUMENT,
  ]),
};


export default (state = initialState, action) => ({
  ...generate(state, action, DOCUMENTS_GET_DOCUMENT, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, DOCUMENTS_GET_DOCUMENT.SUCCESS) && {
        ...action.response,
        selectedDocuments: initialState.selectedDocuments,
      },
    },
  }),

  ...generate(state, action, DOCUMENTS_CREATE_DOCUMENT, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, DOCUMENTS_CREATE_DOCUMENT.SUCCESS) && {
        documents: [
          ...action.response.documents.filter(document => document.type === '2'),
          ...state.documents.filter(document => document.type === '2'),
          ...action.response.documents.filter(document => document.type === '1'),
          ...state.documents.filter(document => document.type === '1'),
        ].slice(0, action.response.rowsPerPage || 50),
      },
    },
  }),

  ...generate(state, action, DOCUMENTS_UPDATE_DOCUMENT, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, DOCUMENTS_UPDATE_DOCUMENT.SUCCESS) && {
        documents: state.documents.map((document) => {
          if (document.id === action.response.document.id) {
            return action.response.document;
          }

          return document;
        }),
        selectedDocuments: initialState.selectedDocuments,
      },
    },
  }),

  ...generate(state, action, DOCUMENTS_DELETE_DOCUMENT, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, DOCUMENTS_DELETE_DOCUMENT.SUCCESS) && {
        documents: state.documents.filter(document => !action.response.ids.includes(document.id)),
        selectedDocuments: initialState.selectedDocuments,
      },
    },
  }),

  ...generate(state, action, DOCUMENTS_SELECT_DOCUMENT, {
    type: types.SYNC,
    adjustment: {
      selectedDocuments: action.selected,
    },
  }),

  ...generate(state, action, DOCUMENTS_TOGGLE_CREATE_FILE_MODAL, {
    type: types.SYNC,
    adjustment: {
      uploadFileModalOpen: !state.uploadFileModalOpen,
    },
  }),

  ...generate(state, action, DOCUMENTS_TOGGLE_CREATE_FOLDER_MODAL, {
    type: types.SYNC,
    adjustment: {
      createFolderModalOpen: !state.createFolderModalOpen,
    },
  }),

  ...generate(state, action, DOCUMENTS_TOGGLE_EDIT_DOCUMENT_MODAL, {
    type: types.SYNC,
    adjustment: {
      editDocumentModalOpen: !state.editDocumentModalOpen,
    },
  }),

  ...generate(state, action, DOCUMENTS_CHANGE_PAGE, {
    type: types.SYNC,
    adjustment: {
      page: action.page,
    },
  }),

  ...generate(state, action, DOCUMENTS_TOGGLE_DOCUMENTS_MODAL, {
    type: types.SYNC,
    adjustment: {
      documentsModalOpen: !state.documentsModalOpen,
    },
  }),
}[action.type] || state);
