/* eslint-disable max-len */
import { generateAction as generate, reduxTypes as types } from 'ggtmo-utils';
import service from '../service';

// Action Creators
import {
  // Async
  NEWS_GET_STORY,
  NEWS_CREATE_STORY,
  NEWS_CREATE_AND_PUBLISH_STORY,
  NEWS_UPDATE_STORY,
  NEWS_UPDATE_AND_PUBLISH_STORY,
  NEWS_DELETE_STORY,
  NEWS_PUBLISH_STORY,
  NEWS_UNPUBLISH_STORY,
  NEWS_PIN_STORY,

  // Sync
  NEWS_SELECT_STORY,
  NEWS_CLEAR_EDITOR,
  NEWS_CHANGE_PAGE,
} from './actionCreators';

// Async
export const getStory = generate(NEWS_GET_STORY, { type: types.ASYNC, service: service.GET.getStory });
export const createStory = generate(NEWS_CREATE_STORY, { type: types.ASYNC, service: service.POST.createStory });
export const createAndPublishStory = generate(NEWS_CREATE_AND_PUBLISH_STORY, { type: types.ASYNC, service: service.POST.createAndPublishStory });
export const updateStory = generate(NEWS_UPDATE_STORY, { type: types.ASYNC, service: service.PUT.updateStory });
export const updateAndPublishStory = generate(NEWS_UPDATE_AND_PUBLISH_STORY, { type: types.ASYNC, service: service.PUT.updateAndPublishStory });
export const deleteStory = generate(NEWS_DELETE_STORY, { type: types.ASYNC, service: service.DELETE.deleteStory });
export const publishStory = generate(NEWS_PUBLISH_STORY, { type: types.ASYNC, service: service.PUT.publishStory });
export const unpublishStory = generate(NEWS_UNPUBLISH_STORY, { type: types.ASYNC, service: service.PUT.unpublishStory });
export const pinStory = generate(NEWS_PIN_STORY, { type: types.ASYNC, service: service.PUT.updateStory });

// Sync
export const selectStories = generate(NEWS_SELECT_STORY, { type: types.SYNC });
export const clearEditor = generate(NEWS_CLEAR_EDITOR, { type: types.SYNC });
export const changePage = generate(NEWS_CHANGE_PAGE, { type: types.SYNC });
