import { create } from 'axios';
import { createAxiosInstance } from 'ggtmo-utils';


const axios = createAxiosInstance(create, {
  baseURL: `${process.env.REACT_APP_BASE_URL}/media/api/v1/`,
});


// GET
const getFile = params => axios.get('', { params });


// POST
const uploadFiles = requestData => axios.post('', requestData);


// PUT
const editFile = requestData => axios.put('', requestData);


// DELETE
const deleteFiles = requestData => axios.delete('', { data: requestData });


export default {
  GET: {
    file: getFile,
  },
  POST: {
    file: uploadFiles,
  },
  PUT: {
    file: editFile,
  },
  DELETE: {
    file: deleteFiles,
  },
};
