export default (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Please enter your name.';
  }

  if (!values.email) {
    errors.email = 'Please enter your email.';
  }

  if (!values.password1) {
    errors.password1 = 'Please enter a password.';
  }

  if (!values.password2) {
    errors.password2 = 'Please repeat your password.';
  }

  if (values.password1 !== values.password2) {
    errors.password1 = 'The provided passwords do not match.';
  }

  return errors;
};
