import {
  generateReducerElement as generate, reduxTypes as types, initializeActions, adjust,
} from 'ggtmo-utils';

// Action Creators
import {
  // Async
  NEWS_GET_STORY,
  NEWS_CREATE_STORY,
  NEWS_CREATE_AND_PUBLISH_STORY,
  NEWS_UPDATE_STORY,
  NEWS_UPDATE_AND_PUBLISH_STORY,
  NEWS_DELETE_STORY,
  NEWS_PUBLISH_STORY,
  NEWS_UNPUBLISH_STORY,
  NEWS_PIN_STORY,

  // Sync
  NEWS_SELECT_STORY,
  NEWS_CLEAR_EDITOR,
  NEWS_CHANGE_PAGE,
} from './actionCreators';


const initialState = {
  stories: [],
  selectedStories: [],
  story: {},
  page: 0,
  count: null,
  actions: initializeActions([
    NEWS_GET_STORY,
    NEWS_CREATE_STORY,
    NEWS_CREATE_AND_PUBLISH_STORY,
    NEWS_UPDATE_STORY,
    NEWS_UPDATE_AND_PUBLISH_STORY,
    NEWS_PUBLISH_STORY,
    NEWS_UNPUBLISH_STORY,
    NEWS_PIN_STORY,
  ]),
};


export default (state = initialState, action) => ({
  // Async
  ...generate(state, action, NEWS_GET_STORY, { type: types.ASYNC }),
  ...generate(state, action, NEWS_CREATE_STORY, { type: types.ASYNC }),
  ...generate(state, action, NEWS_CREATE_AND_PUBLISH_STORY, { type: types.ASYNC }),
  ...generate(state, action, NEWS_UPDATE_STORY, { type: types.ASYNC }),
  ...generate(state, action, NEWS_UPDATE_AND_PUBLISH_STORY, { type: types.ASYNC }),
  ...generate(state, action, NEWS_DELETE_STORY, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, NEWS_DELETE_STORY.SUCCESS) && {
        stories: state.stories.filter(f => f.id !== action.response.id),
      },
    },
  }),
  ...generate(state, action, NEWS_PUBLISH_STORY, { type: types.ASYNC }),
  ...generate(state, action, NEWS_UNPUBLISH_STORY, { type: types.ASYNC }),
  ...generate(state, action, NEWS_PIN_STORY, { type: types.ASYNC }),

  // Sync
  ...generate(state, action, NEWS_SELECT_STORY, {
    type: types.SYNC,
    adjustment: {
      selectedStories: action.selected,
    },
  }),
  ...generate(state, action, NEWS_CLEAR_EDITOR, {
    type: types.SYNC,
    adjustment: {
      story: initialState.story,
    },
  }),
  ...generate(state, action, NEWS_CHANGE_PAGE, {
    type: types.SYNC,
    adjustment: {
      page: action.page,
    },
  }),
}[action.type] || state);
