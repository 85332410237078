import { create } from 'axios';
import { createAxiosInstance } from 'ggtmo-utils';

const axios = createAxiosInstance(create, { baseURL: `${process.env.REACT_APP_BASE_URL}/private/news/api/v1/` });

// GET
const getStory = params => axios.get('', { params: { list: false, ...params } });
const listStories = params => axios.get('', { params: { list: true, ...params } });

// POST
const createStory = requestData => axios.post('', requestData);
const createAndPublishStory = requestData => axios.post('', { createAndPublish: true, ...requestData });

// PUT
const updateStory = requestData => axios.put('', requestData);
const updateAndPublishStory = requestData => axios.put('', { updateAndPublish: true, ...requestData });
const publishStory = requestData => axios.put('', { publish: true, ...requestData });
const unpublishStory = requestData => axios.put('', { unpublish: true, ...requestData });

// DELETE
const deleteStory = requestData => axios.delete('', { data: requestData });

export default {
  GET: {
    getStory,
    listStories,
  },
  POST: {
    createStory,
    createAndPublishStory,
    publishStory,
  },
  PUT: {
    updateStory,
    updateAndPublishStory,
    publishStory,
    unpublishStory,
  },
  DELETE: {
    deleteStory,
  },
};
