import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { wrapper } from 'ggtmo-utils';

// Components
import { Container, Card, CardBody } from 'reactstrap';
import Toolbar from './components/Toolbar';
import AsyncComponent from '../../shared/components/AsyncComponent';
import SubmissionGroupsTable from './components/SubmissionGroupsTable';

// Redux
import {
  getSubmissionGroup,
  selectSubmissionGroup,
} from './redux/actions';

// Images
import adminImage from '../../shared/img/admin.png';

import generateTitle from '../../shared/utils/generateTitle';


class Admin extends PureComponent {
  static propTypes = {
    // Redux
    adminActions: PropTypes.instanceOf(Object).isRequired,
    getSubmissionGroup: PropTypes.func.isRequired,
    selectSubmissionGroup: PropTypes.func.isRequired,
    submissionGroups: PropTypes.instanceOf(Array).isRequired,
    selected: PropTypes.instanceOf(Array).isRequired,

    // Router
    history: RouterPropTypes.history.isRequired,
  };

  componentDidMount() {
    const { getSubmissionGroup: get } = this.props;
    document.title = generateTitle('Submissions');

    get({
      list: true,
    });
  }

  handleOnSelect = (selected) => {
    const { selectSubmissionGroup: select } = this.props;

    select({ selected });
  };

  handleOnView = () => {
    const { selected: [id], history } = this.props;

    history.push(`/admin/submissions/${id}`);
  };

  render() {
    // Props
    const {
      adminActions: { ADMIN_GET_SUBMISSION_GROUP: getSubmissionActionGroup },
      submissionGroups,
      selected,
    } = this.props;

    return (
      <Container>
        <h3 className="page-title">Submissions</h3>
        <p className="page-subhead">View and approve news and events submitted by residents and staff.</p>
        <Card style={{ minHeight: 753 }}>
          <CardBody>
            <Toolbar
              selected={selected}

              // Callbacks
              onView={this.handleOnView}
            />
            <AsyncComponent action={getSubmissionActionGroup}>
              <SubmissionGroupsTable
                submissionGroups={submissionGroups}
                selected={selected}

                // Callbacks
                onSelect={this.handleOnSelect}
              >
                <div className="editor__no-instances mt-5">
                  <h4>There are no submissions to review.</h4>
                  <img src={adminImage} alt="" />
                </div>
              </SubmissionGroupsTable>
            </AsyncComponent>
          </CardBody>
        </Card>
      </Container>
    );
  }
}


const mapStateToProps = (state) => {
  const {
    actions: adminActions,
    submissionGroups,
    selectedSubmissionGroups,
  } = state.admin;

  return {
    // Admin
    adminActions,
    submissionGroups,
    selected: selectedSubmissionGroups,
  };
};


const mapDispatchToProps = {
  getSubmissionGroup,
  selectSubmissionGroup,
};

export default wrapper({
  component: Admin,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
  ],
});
