import { generateAction as generate, reduxTypes as types } from 'ggtmo-utils';
import service from '../service';

// Action creators
import {
  MEDIA_TOGGLE_UPLOAD_MODAL,
  MEDIA_TOGGLE_EDIT_MODAL,
  MEDIA_UPLOAD_FILE,
  MEDIA_GET_FILE,
  MEDIA_UPDATE_FILE,
  MEDIA_DELETE_FILE,
  MEDIA_SELECT_FILE,
  MEDIA_TOGGLE_VIEWER,
  MEDIA_TOGGLE_MEDIA_MODAL,
  MEDIA_TOGGLE_DELETE_CONFIRM_MODAL,
  MEDIA_CHANGE_PAGE,
} from './actionCreators';


export const toggleUploadModal = generate(MEDIA_TOGGLE_UPLOAD_MODAL, {
  type: types.SYNC,
});


export const toggleEditModal = generate(MEDIA_TOGGLE_EDIT_MODAL, {
  type: types.SYNC,
});


export const uploadFile = generate(MEDIA_UPLOAD_FILE, {
  type: types.ASYNC,
  service: service.POST.file,
});


export const getFile = generate(MEDIA_GET_FILE, {
  type: types.ASYNC,
  service: service.GET.file,
});


export const updateFile = generate(MEDIA_UPDATE_FILE, {
  type: types.ASYNC,
  service: service.PUT.file,
});


export const deleteFile = generate(MEDIA_DELETE_FILE, {
  type: types.ASYNC,
  service: service.DELETE.file,
});


export const selectFile = generate(MEDIA_SELECT_FILE, {
  type: types.SYNC,
});


export const toggleViewer = generate(MEDIA_TOGGLE_VIEWER, {
  type: types.SYNC,
});


export const toggleMediaModal = generate(MEDIA_TOGGLE_MEDIA_MODAL, {
  type: types.SYNC,
});


export const toggleDeleteConfirmModal = generate(MEDIA_TOGGLE_DELETE_CONFIRM_MODAL, {
  type: types.SYNC,
});


export const changePage = generate(MEDIA_CHANGE_PAGE, {
  type: types.SYNC,
});
