import { create } from 'axios';
import { createAxiosInstance, getHeaders } from 'ggtmo-utils';


const axios = createAxiosInstance(create, {
  baseURL: `${process.env.REACT_APP_BASE_URL}/users/api/v1/`,
  headerInterceptor: false,
});


// GET
const getProfile = () => axios.get('user/', { headers: getHeaders() });


// POST
const register = requestData => axios.post('registration/', requestData);
const verify = requestData => axios.post('registration/verify-email/', requestData);
const login = requestData => axios.post('login/', requestData);
const logout = () => axios.post('logout/', { headers: getHeaders() });
const forgotPassword = requestData => axios.post('password/reset/', requestData);
const resetPassword = requestData => axios.post('password/reset/confirm/', requestData);


// PUT
const updateProfile = requestData => axios.put('user/', requestData, { headers: getHeaders() });


export default {
  get: {
    profile: getProfile,
  },
  post: {
    register,
    verify,
    login,
    logout,
    forgotPassword,
    resetPassword,
  },
  put: {
    profile: updateProfile,
  },
};
