import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router';
import { reduxForm } from 'redux-form';
import { toast } from 'react-toastify';
import { wrapper } from 'ggtmo-utils';

// Components
import {
  Container, Row, Col, Card, CardBody, UncontrolledTooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin, faSpinner } from '@fortawesome/pro-light-svg-icons';
import EditorForm from './EditorForm';
import AsyncComponent from '../../../shared/components/AsyncComponent';
import EditorControls from '../../../shared/components/EditorControls';
import MediaModal from '../../../shared/components/MediaModal';
import DeleteConfirmModal from '../../../shared/components/DeleteConfirmModal';
import EditorError from '../../../shared/components/EditorError';

// Redux
import {
  toggleDeleteConfirmModal,
} from '../../../redux/actions/editorActions';
import {
  NEWS_CREATE_STORY,
  NEWS_CREATE_AND_PUBLISH_STORY,
  NEWS_UPDATE_STORY,
  NEWS_UPDATE_AND_PUBLISH_STORY,
  NEWS_PUBLISH_STORY,
  NEWS_UNPUBLISH_STORY,
  NEWS_PIN_STORY, NEWS_DELETE_STORY,
} from '../redux/actionCreators';
import {
  getStory,
  createStory,
  createAndPublishStory,
  updateStory,
  updateAndPublishStory,
  deleteStory,
  publishStory,
  unpublishStory,
  pinStory,

  clearEditor,
} from '../redux/actions';
import {
  selectFile,
  toggleMediaModal,
} from '../../Media/redux/actions';
import {
  ADMIN_CREATE_SUBMISSION,
} from '../../Admin/redux/actionCreators';
import {
  createSubmission,
} from '../../Admin/redux/actions';

// Classes
import BaseEditor from '../../../shared/BaseEditor';

// Utils
import getUserType from '../../../shared/utils/getUserType';

class Editor extends BaseEditor {
  static propTypes = {
    // Redux
    newsActions: PropTypes.instanceOf(Object).isRequired,
    adminActions: PropTypes.instanceOf(Object).isRequired,
    story: PropTypes.instanceOf(Object).isRequired,
    mediaModalOpen: PropTypes.bool.isRequired,
    selectedFiles: PropTypes.instanceOf(Array).isRequired,
    images: PropTypes.instanceOf(Array).isRequired,
    deleteConfirmModalOpen: PropTypes.bool.isRequired,

    // Form
    editorForm: PropTypes.instanceOf(Object),
    change: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    // Form
    editorForm: {},
  };

  appName = 'news';

  modelName = 'story';

  actionCreators = {
    create: NEWS_CREATE_STORY,
    createAndPublish: NEWS_CREATE_AND_PUBLISH_STORY,
    update: NEWS_UPDATE_STORY,
    updateAndPublish: NEWS_UPDATE_AND_PUBLISH_STORY,
    delete: NEWS_DELETE_STORY,
    publish: NEWS_PUBLISH_STORY,
    unpublish: NEWS_UNPUBLISH_STORY,
    pin: NEWS_PIN_STORY,
    submit: ADMIN_CREATE_SUBMISSION,
  };

  toastMessages = {
    create: {
      SUCCESS: 'Created story.',
      ERROR: 'Error creating story.',
    },
    createAndPublish: {
      SUCCESS: 'Created and published story. Your changes are visible to the public.',
      ERROR: 'Error creating story.',
    },
    update: {
      SUCCESS: 'Saved story.',
      ERROR: 'Error saving story.',
    },
    updateAndPublish: {
      SUCCESS: 'Saved and published story. Your changes are visible to the public.',
      ERROR: 'Error saving story.',
    },
    delete: {
      SUCCESS: 'Deleted story,',
      ERROR: 'Error deleting story.',
    },
    publish: {
      SUCCESS: 'Published story. Your changes are visible to the public.',
      ERROR: 'Error publishing story.',
    },
    unpublish: {
      SUCCESS: 'Unpublished story. It is no longer visible to the public.',
      ERROR: 'Error unpublishing story.',
    },
    submit: {
      SUCCESS: 'Submitted for review. You will be notified if it is approved for publish.',
      ERROR: 'Error submitting for review.',
    },
  };

  handleOnPinStory = () => {
    const { pinStory: pin, story } = this.props;

    pin({
      id: story.id,
      pinned: !story.pinned,
      updateImageSet: false,
      updateDocumentSet: false,
    })
      .then((action) => {
        if (action.type === this.actionCreators.pin.SUCCESS) {
          toast(`Successfully ${action.response.story.pinned ? 'pinned' : 'unpinned'} story.`, {
            type: toast.TYPE.SUCCESS,
          });
        }
      });
  };

  renderCustomPills = () => {
    // Props
    const {
      newsActions: {
        NEWS_PIN_STORY: pinStoryAction,
      },
      story,
    } = this.props;

    return (
      <>
        <span
          role="presentation"
          className="editor__controls__pin-btn shadow-sm hover-pointer"
          id="pin"
          onClick={this.handleOnPinStory}
        >
          <FontAwesomeIcon
            icon={pinStoryAction.loading ? faSpinner : faMapPin}
            fixedWidth
            spin={pinStoryAction.loading}
          />
          {story.pinned ? ' Unpin' : ' Pin'}
        </span>
        <UncontrolledTooltip target="pin" placement="top" delay={0}>
          {story.pinned
            ? 'Unpin this story'
            : 'Pin this story as the first on the home page.'
          }
        </UncontrolledTooltip>
      </>
    );
  };

  render() {
    // Props
    const {
      newsActions: {
        NEWS_GET_STORY: getStoryAction,
        NEWS_CREATE_STORY: createStoryAction,
        NEWS_CREATE_AND_PUBLISH_STORY: createAndPublishStoryAction,
        NEWS_UPDATE_STORY: updateStoryAction,
        NEWS_UPDATE_AND_PUBLISH_STORY: updateAndPublishStoryAction,
        NEWS_DELETE_STORY: deleteStoryAction,
        NEWS_PUBLISH_STORY: publishStoryAction,
        NEWS_UNPUBLISH_STORY: unpublishStoryAction,
        NEWS_PIN_STORY: pinStoryAction,
      },
      adminActions: {
        ADMIN_CREATE_SUBMISSION: createSubmissionAction,
      },
      story,
      editorForm,
      mediaModalOpen,
      pristine,
      dirty,
      match: { params },
      deleteConfirmModalOpen,
    } = this.props;

    if (getStoryAction.error) {
      return (
        <EditorError
          instanceType="story"
          action={getStoryAction}
        />
      );
    }

    return (
      <>
        <Prompt message="You have made unsaved changes. Are you sure you want to leave the page?" when={dirty} />
        <Container>
          <Row className="pb-5 pb-md-0">
            <Col xl={8} lg={7} md={12} sm={12}>
              <Card>
                <CardBody>
                  <AsyncComponent action={getStoryAction}>
                    <EditorForm
                      initialValues={story}
                      enableReinitialize
                    />
                  </AsyncComponent>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} lg={4} md={12} sm={12} style={{ height: '100%' }}>
              <Card className="shadow pb-0">
                <CardBody>
                  <EditorControls
                    userType={getUserType()}
                    instanceType="story"
                    isNew={params.id === 'new'}
                    pristine={pristine}
                    instance={story}

                    // Components
                    customPills={this.renderCustomPills}

                    // Actions
                    createAction={createStoryAction}
                    createAndPublishAction={createAndPublishStoryAction}
                    updateAction={updateStoryAction}
                    updateAndPublishAction={updateAndPublishStoryAction}
                    deleteAction={deleteStoryAction}
                    publishAction={publishStoryAction}
                    unpublishAction={unpublishStoryAction}
                      // TODO: Check this, it is here just to trigger an update when the loading state changes.
                    pinAction={pinStoryAction}
                    submitAction={createSubmissionAction}

                    // Callbacks
                    onCreate={this.handleOnCreate}
                    onCreateAndPublish={this.handleOnCreateAndPublish}
                    onUpdate={this.handleOnUpdate}
                    onUpdateAndPublish={this.handleOnUpdateAndPublish}
                    onDelete={this.handleOnToggleDeleteConfirmModal}
                    onPublish={this.handleOnPublish}
                    onUnpublish={this.handleOnUnpublish}
                    onSubmit={this.handleOnSubmit}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <MediaModal
          open={mediaModalOpen}

          // Callbacks
          toggle={this.handleToggleMediaModal}

          // Shared usage
          instance={editorForm.values}
        />
        <DeleteConfirmModal
          open={deleteConfirmModalOpen}
          action={deleteStoryAction}
          instanceType="story"

          // Callbacks
          toggle={this.handleOnToggleDeleteConfirmModal}
          deleteAnyway={this.handleOnDelete}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    deleteConfirmModalOpen,
  } = state.editor;

  const {
    actions: newsActions,
    story,
  } = state.news;

  const {
    mediaModalOpen,
    selectedFiles,
    images,
  } = state.media;

  const {
    editorForm,
  } = state.form;

  const {
    actions: adminActions,
  } = state.admin;

  return {
    // Editor
    deleteConfirmModalOpen,

    // News
    newsActions,
    story,
    instance: story,

    // Media
    mediaModalOpen,
    selectedFiles,
    images,

    // Form
    editorForm,

    // Admin
    adminActions,
  };
};

const mapDispatchToProps = {
  // Async
  get: getStory,
  create: createStory,
  createAndPublish: createAndPublishStory,
  update: updateStory,
  updateAndPublish: updateAndPublishStory,
  delete: deleteStory,
  publish: publishStory,
  unpublish: unpublishStory,
  pinStory,
  submit: createSubmission,

  // Sync
  selectFile,
  toggleMediaModal,
  clearEditor,
  toggleDeleteConfirmModal,
};

const withForm = {
  form: 'editorForm',
};

export default wrapper({
  component: Editor,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    reduxForm(withForm),
  ],
});
