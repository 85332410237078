import { create } from 'axios';
import { createAxiosInstance } from 'ggtmo-utils';

const axios = createAxiosInstance(create, { baseURL: `${process.env.REACT_APP_BASE_URL}/private/events/api/v1/` });

// GET
const getEvent = params => axios.get('', { params: { list: false, ...params } });
const getEvents = params => axios.get('', { params: { list: true, ...params } });

// POST
const createEvent = requestData => axios.post('', requestData);
const createAndPublishEvent = requestData => axios.post('', { createAndPublish: true, ...requestData });
const convertEventToStory = requestData => axios.post('convert/', requestData);

// PUT
const updateEvent = requestData => axios.put('', requestData);
const updateAndPublishEvent = requestData => axios.put('', { updateAndPublish: true, ...requestData });
const publishEvent = requestData => axios.put('', { publish: true, ...requestData });
const unpublishEvent = requestData => axios.put('', { unpublish: true, ...requestData });

// DELETE
const deleteEvent = requestData => axios.delete('', { data: requestData });

export default {
  GET: {
    getEvent,
    getEvents,
  },
  POST: {
    createEvent,
    createAndPublishEvent,
    convertEventToStory,
  },
  PUT: {
    updateEvent,
    updateAndPublishEvent,
    publishEvent,
    unpublishEvent,
  },
  DELETE: {
    deleteEvent,
  },
};
