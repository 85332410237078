// TODO: The delete confirm modal should be handled by this component.
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

// Components
import { UncontrolledTooltip, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faGlobeEurope } from '@fortawesome/pro-light-svg-icons';
import AsyncButton from './AsyncButton';

// Utils
import { ADMIN, RESIDENT } from '../utils/userTypes';


const buttonDefaultProps = {
  className: 'rounded',
  outline: true,
};


const EditorControlsHeader = ({
  instance,
  instanceType,
  isNew,

  // Components
  customPills,
}) => {
  const isVowel = ['a', 'e', 'i', 'o', 'u'].map(vowel => instanceType.startsWith(vowel)).includes(true);

  return (
    <>
      {isNew ? (
        <h3 className="page-title">Creating: {instanceType}</h3>
      ) : (
        <div>
          <div>
            <h3 className="page-title mb-0 pb-0">Editing: {instanceType}</h3>
          </div>
          <div className="mt-2">
            <EditorControlsPills
              instance={instance}
              instanceType={instanceType}

              // Components
              customPills={customPills}
            />
          </div>
        </div>
      )}
      <p className="page-subhead pt-3">
        Use the editor to {isNew ? `create ${isVowel ? 'an' : 'a'}` : 'edit this'} {instanceType}. When published{', '}
        {isVowel ? 'an' : 'a'} {instanceType} is visible to the public.
      </p>
    </>
  );
};

EditorControlsHeader.propTypes = {
  instance: PropTypes.instanceOf(Object).isRequired,
  instanceType: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,

  // Components
  customPills: PropTypes.node.isRequired,
};


const EditorControlsPills = ({
  instance,
  instanceType,

  // Components
  customPills,
}) => (
  <div className="d-table">
    <div className="d-table-cell" style={{ verticalAlign: 'middle' }}>
      {instance.isLive ? (
        <>
          <span className="editor__controls__live shadow-sm" id="live">
            <FontAwesomeIcon icon={faCheck} fixedWidth />{' '}
            Live
          </span>
          <a href={instance.url} target="_blank" rel="noreferrer noopener">
            <span className="editor__controls__preview shadow-sm" id="public">
              <FontAwesomeIcon icon={faGlobeEurope} fixedWidth />{' '}
              Preview
            </span>
          </a>
          <UncontrolledTooltip target="live" placement="top" delay={0}>
            This {instanceType} is live and visible to the public.
          </UncontrolledTooltip>
          <UncontrolledTooltip target="public" placement="top" delay={0}>
            Go to this {instanceType} on the public site.
          </UncontrolledTooltip>
          {customPills()}
        </>
      ) : (
        <>
          <span className="editor__controls__not-live shadow-sm" id="not-live">
            <FontAwesomeIcon icon={faTimes} fixedWidth />{' '}
            Not live
          </span>
          <UncontrolledTooltip target="not-live" placement="top">
            This {instanceType} is not live and not visible to the public.
          </UncontrolledTooltip>
        </>
      )}
    </div>
  </div>
);

EditorControlsPills.propTypes = {
  instance: PropTypes.instanceOf(Object).isRequired,
  instanceType: PropTypes.string.isRequired,

  // Components
  customPills: PropTypes.node.isRequired,
};


const tooltipDefaultProps = {
  placement: 'left',
  delay: 1000,
};


const EditorControlsAdminButtons = ({
  instance,
  instanceType,
  isNew,
  pristine,
  createDisabled,
  updateDisabled,
  createAndPublishDisabled,
  updateAndPublishDisabled,
  publishDisabled,
  unpublishDisabled,
  deleteDisabled,
  deleteAndUnpublishDisabled,

  // Actions
  createAction,
  createAndPublishAction,
  updateAction,
  updateAndPublishAction,
  publishAction,
  unpublishAction,

  // Callbacks
  onCreate,
  onCreateAndPublish,
  onUpdate,
  onUpdateAndPublish,
  onPublish,
  onUnpublish,
  onDelete,
}) => (
  <div>
    {/* Create or save button */}
    {isNew ? (
      <div id="create-button" className="mb-3">
        <AsyncButton
          {...buttonDefaultProps}
          color="primary"
          onClick={onCreate}
          action={createAction}
          disabled={createDisabled}
        >
          Create
        </AsyncButton>
        <UncontrolledTooltip target="create-button" {...tooltipDefaultProps}>
          Make some changes, then you can create.
        </UncontrolledTooltip>
      </div>
    ) : (
      <div id="save-button" className="mb-3">
        <AsyncButton
          {...buttonDefaultProps}
          color="primary"
          onClick={onUpdate}
          action={updateAction}
          disabled={updateDisabled}
        >
          Save
        </AsyncButton>
        <UncontrolledTooltip target="save-button" {...tooltipDefaultProps}>
          Make some changes, then you can save.
        </UncontrolledTooltip>
      </div>
    )}

    {/* Create and publish or save and publish button */}
    {isNew ? (
      <div id="create-and-publish-button" className="mb-3">
        <AsyncButton
          {...buttonDefaultProps}
          color="primary"
          onClick={onCreateAndPublish}
          action={createAndPublishAction}
          disabled={createAndPublishDisabled}
        >
          Create & publish
        </AsyncButton>
        <UncontrolledTooltip target="create-and-publish-button" {...tooltipDefaultProps}>
          Make some changes, then you can create and publish.
        </UncontrolledTooltip>
      </div>
    ) : (
      <div id="save-and-publish-button" className="mb-3">
        <AsyncButton
          {...buttonDefaultProps}
          color="primary"
          onClick={(pristine && !isNew) ? onPublish : onUpdateAndPublish}
          action={(pristine && !isNew) ? publishAction : updateAndPublishAction}
          disabled={(pristine && !isNew) ? publishDisabled : updateAndPublishDisabled}
        >
          {(pristine && !isNew) ? 'Publish' : 'Save & publish'}
        </AsyncButton>
        <UncontrolledTooltip target="save-and-publish-button" {...tooltipDefaultProps}>
          Make some changes, then you can {(pristine && !isNew) ? 'publish' : 'save and publish' }.
        </UncontrolledTooltip>
      </div>
    )}

    {/* Unpublish button */}
    <div id="unpublish-button" className="mb-3">
      <AsyncButton
        {...buttonDefaultProps}
        color="warning"
        onClick={onUnpublish}
        action={unpublishAction}
        disabled={unpublishDisabled}
        className="rounded"
      >
        Unpublish
      </AsyncButton>
      {unpublishDisabled && (
        <UncontrolledTooltip target="unpublish-button" {...tooltipDefaultProps}>
          This {instanceType} is already unpublished.
        </UncontrolledTooltip>
      )}
    </div>

    {/* Delete and unpublish button */}
    <div id="delete-and-unpublish-button" className="mb-3">
      <Button
        {...buttonDefaultProps}
        color="danger"
        onClick={onDelete}
        disabled={deleteDisabled && deleteAndUnpublishDisabled}
        className="rounded"
      >
        {instance.isLive ? 'Delete & unpublish' : 'Delete'}
      </Button>
      {(deleteDisabled || deleteAndUnpublishDisabled) && (
        <UncontrolledTooltip target="delete-and-unpublish-button" {...tooltipDefaultProps}>
          This {instanceType} has not been saved yet.
        </UncontrolledTooltip>
      )}
    </div>
  </div>
);

EditorControlsAdminButtons.propTypes = {
  userType: PropTypes.string.isRequired,
  instanceType: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  instance: PropTypes.instanceOf(Object).isRequired,
  createDisabled: PropTypes.bool.isRequired,
  updateDisabled: PropTypes.bool.isRequired,
  createAndPublishDisabled: PropTypes.bool.isRequired,
  updateAndPublishDisabled: PropTypes.bool.isRequired,
  publishDisabled: PropTypes.bool.isRequired,
  unpublishDisabled: PropTypes.bool.isRequired,
  deleteDisabled: PropTypes.bool.isRequired,
  deleteAndUnpublishDisabled: PropTypes.bool.isRequired,

  // Actions
  createAction: PropTypes.instanceOf(Object).isRequired,
  createAndPublishAction: PropTypes.instanceOf(Object).isRequired,
  updateAction: PropTypes.instanceOf(Object).isRequired,
  updateAndPublishAction: PropTypes.instanceOf(Object).isRequired,
  publishAction: PropTypes.instanceOf(Object).isRequired,
  unpublishAction: PropTypes.instanceOf(Object).isRequired,

  // Callbacks
  onCreate: PropTypes.func.isRequired,
  onCreateAndPublish: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateAndPublish: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
  onUnpublish: PropTypes.func.isRequired,
};


const EditorControlsResidentButtons = ({
  isNew,
  createOrSaveDisabled,
  submitDisabled,

  // Actions
  createAction,
  updateAction,
  submitAction,

  // Callbacks
  onCreate,
  onUpdate,
  onSubmit,
}) => (
  <div>
    <div id="save-button" className="mb-3">
      <AsyncButton
        {...buttonDefaultProps}
        color="primary"
        onClick={isNew ? onCreate : onUpdate}
        action={isNew ? createAction : updateAction}
        data-publish="false"
        disabled={createOrSaveDisabled}
        className="rounded"
      >
        {isNew ? 'Create' : 'Save'}
      </AsyncButton>
      {createOrSaveDisabled && (
        <UncontrolledTooltip target="save-button" {...tooltipDefaultProps}>
          Make some changes, then you can {isNew ? 'create' : 'save'}.
        </UncontrolledTooltip>
      )}
    </div>
    <div id="submit-for-review">
      <AsyncButton
        {...buttonDefaultProps}
        color="primary"
        onClick={onSubmit}
        action={submitAction}
        disabled={submitDisabled}
        className="rounded"
      >
        Submit for review
      </AsyncButton>
      {submitDisabled && (
        <UncontrolledTooltip target="submit-for-review" {...tooltipDefaultProps}>
          Save first, then submit for review.
        </UncontrolledTooltip>
      )}
    </div>
  </div>
);

EditorControlsResidentButtons.propTypes = {
  isNew: PropTypes.bool.isRequired,
  createOrSaveDisabled: PropTypes.bool.isRequired,
  submitDisabled: PropTypes.bool.isRequired,

  // Actions
  createAction: PropTypes.instanceOf(Object).isRequired,
  updateAction: PropTypes.instanceOf(Object).isRequired,
  submitAction: PropTypes.instanceOf(Object).isRequired,

  // Callbacks
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};


const EditorControls = ({
  userType,
  instanceType,
  isNew,
  pristine,
  instance,
  submission,

  // Components
  customPills,

  // Actions
  createAction,
  createAndPublishAction,
  updateAction,
  updateAndPublishAction,
  publishAction,
  unpublishAction,
  submitAction,

  // Callbacks
  onCreate,
  onCreateAndPublish,
  onUpdate,
  onUpdateAndPublish,
  onDelete,
  onPublish,
  onUnpublish,
  onSubmit,
}) => {
  // TODO: Soon to rename ``dateTimePosted`` -> ``dateTimeLastPublished``.
  const dateTimeUpdatedAndPostedEqual = moment(instance.dateTimeUpdated).isBefore(instance.dateTimePosted);
  // There is no point in saving if the current
  // values are identical to values just loaded
  // from the database.
  const createDisabled = !isNew || pristine;
  const updateDisabled = isNew || pristine;
  // We can't create and publish an instance if
  // the editor is completely empty.
  const createAndPublishDisabled = !isNew || pristine;
  // This one is a little more complicated. We
  // need to check a few things: have any changes
  // been made? Is the instance live? And is the
  // time of the more recent update the same as
  // the time of the most recent publish?
  const updateAndPublishDisabled = pristine || (isNew && dateTimeUpdatedAndPostedEqual);
  // Publish is disabled for new instances and
  // instances or when the time of last update is
  // the same (or before) the time of las publish.
  const publishDisabled = isNew || dateTimeUpdatedAndPostedEqual;
  // We can't unpublish a new instance or an
  // instance that is not already live.
  const unpublishDisabled = isNew || !instance.isLive;
  // We can't delete a new instance.
  const deleteDisabled = isNew;
  // We can't delete or unpublish a new instance
  // or an instance that isn't already live.
  const deleteAndUnpublishDisabled = isNew || !instance.isLive;
  // This one is also a little more complicated.
  // We need to check: have any changes been made
  // in the editor? Is the time of the more recent
  // submission after the most recent update (this
  // means the user has not changed it since they
  // last submitted for review). TODO: Finish this.
  const submitDisabled = (
    moment(instance.dateTimeOfLastSubmission).isAfter(instance.dateTimeUpdated, 'second')
    || moment(submission.dateTimeSubmitted).isAfter(instance.dateTimeUpdated, 'second')
    || !pristine
  );

  return (
    <div className="editor__controls">
      <EditorControlsHeader
        instance={instance}
        instanceType={instanceType}
        isNew={isNew}

        // Components
        customPills={customPills}
      />
      <hr />
      {userType === ADMIN && (
        <EditorControlsAdminButtons
          userType={userType}
          instance={instance}
          instanceType={instanceType}
          isNew={isNew}
          pristine={pristine}
          createDisabled={createDisabled}
          updateDisabled={updateDisabled}
          createAndPublishDisabled={createAndPublishDisabled}
          updateAndPublishDisabled={updateAndPublishDisabled}
          deleteDisabled={deleteDisabled}
          deleteAndUnpublishDisabled={deleteAndUnpublishDisabled}
          publishDisabled={publishDisabled}
          unpublishDisabled={unpublishDisabled}

          // Actions
          createAction={createAction}
          createAndPublishAction={createAndPublishAction}
          updateAction={updateAction}
          updateAndPublishAction={updateAndPublishAction}
          publishAction={publishAction}
          unpublishAction={unpublishAction}

          // Callbacks
          onCreate={onCreate}
          onCreateAndPublish={onCreateAndPublish}
          onUpdate={onUpdate}
          onUpdateAndPublish={onUpdateAndPublish}
          onDelete={onDelete}
          onPublish={onPublish}
          onUnpublish={onUnpublish}
        />
      )}
      {userType === RESIDENT && (
        <EditorControlsResidentButtons
          isNew={isNew}
          createDisabled={createDisabled}
          updateDisabled={updateDisabled}
          submitDisabled={submitDisabled}

          // Actions
          createAction={createAction}
          updateAction={updateAction}
          submitAction={submitAction}

          // Callbacks
          onCreate={onCreate}
          onUpdate={onUpdate}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

EditorControls.propTypes = {
  userType: PropTypes.string.isRequired,
  instanceType: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  instance: PropTypes.instanceOf(Object).isRequired,
  submission: PropTypes.instanceOf(Object).isRequired,

  // Components
  customPills: PropTypes.node,

  // Actions
  createAction: PropTypes.instanceOf(Object).isRequired,
  createAndPublishAction: PropTypes.instanceOf(Object).isRequired,
  updateAction: PropTypes.instanceOf(Object).isRequired,
  updateAndPublishAction: PropTypes.instanceOf(Object).isRequired,
  publishAction: PropTypes.instanceOf(Object).isRequired,
  unpublishAction: PropTypes.instanceOf(Object).isRequired,
  submitAction: PropTypes.instanceOf(Object),

  // Callbacks
  onCreate: PropTypes.func.isRequired,
  onCreateAndPublish: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateAndPublish: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
  onUnpublish: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

EditorControls.defaultProps = {
  // Components
  customPills: () => null,

  // Actions
  submitAction: { loading: false },

  // Callbacks
  onSubmit: () => {},
};


const mapStateToProps = (state) => {
  const {
    user,
  } = state.account;

  const {
    submission,
  } = state.admin;

  return {
    // Account
    user,

    // Admin
    submission,
  };
};

export default connect(mapStateToProps)(EditorControls);
